.container {
  width: 100%;
  height: 100%;
  font-family: "poppins", sans-serif;
}
.cart_page {
  display: flex;
  flex-direction: row;
  margin: 2rem;
}
.cart_list {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.head_left {
  width: 50%;
}
.head_right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.remove_icon {
  font-size: 1.25rem;
}
.remove button {
  border: none;
  background: none;
  font-size: 1rem;
  padding: 0 0.5rem;
}
.description_head {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  border-bottom: 1px solid #bbbbbb;
}
.des_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 10%;
  justify-content: space-between;
}
.left_check input {
  width: 20px;
  height: 40px;
}
.left_product {
  font-size: 1rem;
  font-weight: 400;
  color: #707177;
}
.des_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 45%;
  justify-content: space-between;
}
.right_quantity {
  font-size: 1rem;
  font-weight: 400;
  color: #707177;
}
.right_price {
  font-size: 1rem;
  font-weight: 400;
  color: #707177;
}
.product_one {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1rem;
  border-bottom: 1px solid #bbbbbb;
  padding: 0 0 0.75rem;
}
.product_left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  align-items: center;
}
.product_check {
  width: 20%;
}
.product_check input {
  height: 1.25rem;
  width: 1.25rem;
}
.product_card {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: flex-start;
}
.card_left {
  width: 30%;
  height: 50%;
  /* background-color: #d0d1db; */
  border-radius: 15px;
}
.card_left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.card_right {
  padding: 0 1.25rem;
}
.card_right h1 {
  font-size: 1.25rem;
  font-weight: 400;
}
.card_right p {
  color: #707177;
}
.product_center {
  width: 37%;
}
.quantity {
  display: flex;
  border: 1px solid #b7b8ba;
  border-radius: 5px;
  padding: 0.25rem 1.5rem;
  width: 115px;
  align-items: center;
  justify-content: space-between;
}
.quantity button {
  border: none;
  background: none;
  font-size: 1.5rem;
}
.quantity input {
  border: none;
  background: transparent;
  width: 35px;
  text-align: center;
  outline: none;
}
.quantity_delete {
  display: flex;
  flex-direction: row;
  margin: 0.75rem;
}
.delete_icon {
  cursor: pointer;
}
.delete {
  font-size: 0.95rem;
  padding: 0 0.5rem;
}
.product_right {
  width: 10%;
  font-size: 1.25rem;
  font-weight: 600;
}
.producttwo_left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  align-items: center;
}
.confirmation {
  width: 35%;
  margin: 0 2.5rem;
  height: 100%;
}
.confirmation_dialogue {
  width: 100%;

  border: 1px solid #bbbbbb;
  border-radius: 10px;
  padding: 1.25rem;
}
.dialogue_head {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-bottom: 1px solid #bbbbbb;
}
.subtottal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.subtottal p {
  color: #707177;
  font-size: 1.2rem;
}

.discount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.discount p {
  color: #707177;
  font-size: 1.2rem;
}
.dialogue_body {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.grandtotal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.grandtotal p {
  font-size: 1.5rem;
}
.checkout {
  margin-left: auto;
  margin-right: auto;
}

.checkout button {
  border: none;
  background: black;
  color: #fff;
  width: 300px;
  height: 40px;
  font-size: 1.25rem;
  border-radius: 5px;
  cursor: pointer;
}
.add_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
.add_item button {
  border: none;
  background: #020245;
  color: #fff;
  width: 120px;
  height: 35px;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .cart_page {
    display: flex;
    flex-direction: column;
    margin: 1rem;
  }
  .cart_list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .head_left {
    width: 50%;
  }
  .head_left h1 {
    font-size: 1.5rem;
  }
  .head_right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .remove_icon {
    font-size: 1rem;
  }
  .remove button {
    border: none;
    background: none;
    font-size: 1rem;
    padding: 0 0.25rem;
  }
  .des_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 28%;
    justify-content: space-between;
  }
  .left_check input {
    width: 13px;
    height: 13px;
  }
  .left_product {
    font-size: 0.95rem;
    font-weight: 400;
    color: #707177;
  }
  .des_right {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    justify-content: space-between;
  }
  .right_quantity {
    font-size: 0.95rem;
    font-weight: 400;
    color: #707177;
  }
  .right_price {
    font-size: 0.95rem;
    font-weight: 400;
    color: #707177;
  }
  .product_one {
    margin: 1rem 0;
  }
  .product_left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 55%;
    align-items: center;
  }
  .product_check {
    width: 15%;
  }
  .product_check input {
    height: 17px;
    width: 17px;
  }
  .product_card {
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: flex-start;
  }
  .card_left {
    width: 50%;
    height: 50%;
    /* background-color: #d0d1db; */
    border-radius: 15px;
  }
  .card_left img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .card_right {
    padding: 0 1rem;
  }
  .card_right h1 {
    font-size: 1rem;
    font-weight: 400;
  }
  .card_right p {
    color: #707177;
    font-size: 0.95rem;
  }

  .product_center {
    width: 30%;
  }
  .quantity {
    display: flex;
    border: 1px solid #b7b8ba;
    border-radius: 5px;
    padding: 0.25rem 0.75rem;
    width: 80px;
    align-items: center;
    justify-content: space-between;
  }
  .quantity button {
    border: none;
    background: none;
    font-size: 1.25rem;
  }
  .quantity input {
    border: none;
    background: transparent;
    width: 35px;
    text-align: center;
    outline: none;
  }
  .quantity_delete {
    display: flex;
    flex-direction: row;
    margin: 0.5rem;
  }
  .delete {
    font-size: 0.75rem;
    padding: 0 0.5rem;
  }
  .product_right {
    width: 15%;
    font-size: 1rem;
    font-weight: 600;
  }
  .confirmation {
    width: 100%;
    margin: 0rem;
  }

  .subtottal p {
    color: #707177;
    font-size: 1rem;
  }

  .discount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .discount p {
    color: #707177;
    font-size: 1rem;
  }
  .dialogue_body {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .grandtotal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
  }
  .grandtotal p {
    font-size: 1.25rem;
  }
  .checkout {
    margin-left: auto;
    margin-right: auto;
  }

  .checkout button {
    border: none;
    background: black;
    color: #fff;
    width: 300px;
    height: 40px;
    font-size: 1.25rem;
    border-radius: 5px;
    cursor: pointer;
  }
  .add_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin: 0.5rem 0;
  }
  .add_item button {
    border: none;
    background: #020245;
    color: #fff;
    width: 100px;
    height: 35px;
    padding: 0.5rem;
    border-radius: 4px;
    font-size: 0.85rem;
  }
}

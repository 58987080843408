.container{
    width: 100%;
    height: 90vh;
    background-color: #f5f2ff;
    font-family: "poppins", sans-serif;
}
.search_page{
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
}
.product_card{
    width: 350px;
    height: 350px;
    /* border: 1px solid #f1edf4; */
    margin: 1.5rem 0;
    padding: 1.25rem;
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: all 0.2s ease-in-out;
}
.product_card:hover {
    margin-top: 3px;
    box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
  }
.product_image{
    width: 60%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}
.product_image img{
    width: 100%;
    height: 100%;
}
.card_body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    align-items: flex-start;
}
.product_description{
    display: flex;
    flex-direction: column;
  
    justify-content: center;
}
.product_name p{
    font-family: "poppins";
    font-size: 1.5rem;
    color: #24172d;
    font-weight: 600;
}
.product_rate p{
    font-family: "poppins";
    font-size: 1.25rem;
    color: #24172d;
}
.cart{
    font-size: 1.75rem;
    cursor: pointer;
}
.product_firstrow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.quantity_popup{
    width: 350px;
    height: 325px;
    padding: 1.25rem;
    background-color: #322d2d;
    border-radius: 2rem;
    color: #fff;
    position: absolute;
    top: 40%;
    right: 42%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.popup_page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 100%;
}
.popup_close{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    font-size: 1.5rem;
}
.popup_page h4{
    font-size: 1.25rem;
}
.popup_top{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.quantity{
    font-size: 1.25rem;
}
.quantity_value{
    display: flex;
    align-items: center;
    border:3px solid #d4c7ca;
    padding: 0.5rem 1.25rem;
    border-radius: 15px;
    justify-content: space-between;
    width: 60%;
    background-color: #fff;
}
.quantity_value button{
    border: none;
    background: transparent;
  
    font-size: 1.75rem;
}
.quantity_value input{
    width: 30%;
    border: none;
    background: none;
    outline: none;
    font-size: 1.5rem;

}
.popup_bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.bulk_item{
    font-size: 1.25rem;
}
.item_value input{
    outline: none;
    border: none;
    font-size: 1.25rem;
    padding: 0.5rem;
    width: 185px;
    border-radius: 8px;
    border:3px solid #d4c7ca;
} 
.cart_button{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}
.cart_button button{
    border: none;
    width: 130px;
    height: 45px;
    border-radius: 0.5rem;
    background: #151623;
    color: #fff;
    font-size: 1rem;
    padding: 1rem;
    cursor: pointer;
}
.container {
  width: 100%;
  height: 90vh;
  background-color: #f5f2ff;
  font-family: "poppins", sans-serif;
  display: flex;
  flex-direction: row;
}
.selection_page {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}
.product_name {
  border-bottom: 2px solid #34343d;
  width: 50%;
}
.product_name h2 {
  font-size: 2rem;
  font-weight: 600;
}
.section_selection {
  margin: 1rem;
  padding: 1rem;
}
.section_selection h3 {
  font-size: 1.5rem;
  font-weight: 500;
}
.selection_container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.catogry {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* flex-wrap: wrap; */
  margin: 1rem 0;
}
.item_card {
  width: 350px;
  height: 350px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 1rem;
  margin: 0 1rem;
  transition: all 0.2s ease-in-out;
}
.item_card:hover {
  margin-top: 3px;
  box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
}
.item_top {
  font-size: 120px;
}
.item_bottom p {
  font-size: 1.5rem;
}
.bulkitem_card {
  width: 350px;
  height: 350px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 1rem;
  margin: 0 1rem;
  transition: all 0.2s ease-in-out;
}
.bulkitem_card:hover {
  margin-top: 3px;
  box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
}
.bulkitem_top {
  font-size: 120px;
}
.bulkitem_bottom p {
  font-size: 1.5rem;
}
.item_selection {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 50%;
}
.chart_head {
  display: flex;
  flex-direction: column;
  height: 40%;
  background: #322d2d;
  width: 80%;
  padding: 1rem;
  color: #fff;
  justify-content: space-between;
  border-radius: 0.5rem;
}
.size_name h2 {
  font-size: 1.25rem;
  font-weight: 400;
}
.size_chart {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: space-around;
}
.size_chart button {
  border: none;
  font-size: 1.25rem;
  padding: 0.75rem;
  border-radius: 30px;
  width: 50px;
  background-color: #fff;
}
.quantity {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}
.quantity_name p {
  font-size: 1.25rem;
}
.quantity_add {
  display: flex;
  align-items: center;
  /* border:3px solid #d4c7ca; */
  padding: 0.5rem 1rem;
  border-radius: 15px;
  justify-content: space-between;
  width: 30%;
  background-color: #fff;
}
.quantity_add button {
  border: none;
  background: transparent;

  font-size: 1.75rem;
}
.quantity_add input {
  width: 30%;
  border: none;
  background: none;
  outline: none;
  font-size: 1.5rem;
}
.section_bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.cancel button {
  border: none;
  width: 130px;
  height: 45px;
  border-radius: 0.5rem;
  background: #ffffff;
  color: #111225;
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
}
.bulk_cancel button {
  border: none;
  width: 130px;
  height: 45px;
  border-radius: 0.5rem;
  background: #ffffff;
  color: #111225;
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
}
.cart_button button {
  border: none;
  width: 130px;
  height: 45px;
  border-radius: 0.5rem;
  background: #111225;
  color: #fff;
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
}
.bulkcart_button button {
  border: none;
  width: 130px;
  height: 45px;
  border-radius: 0.5rem;
  background: #111225;
  color: #fff;
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
}
.new_standard {
  display: flex;
  flex-direction: column;
  height: 220px;
  background: #322d2d;
  width: 100%;
  padding: 1rem;
  color: #fff;
  justify-content: space-between;
  border-radius: 0.5rem;
}
.nw_cancel button {
  border: none;
  width: 120px;
  height: 40px;
  border-radius: 0.5rem;
  background: #ffffff;
  color: #111225;
  font-size: 1rem;

  cursor: pointer;
}
.bulkcart_new button {
  border: none;
  width: 120px;
  height: 40px;
  border-radius: 0.5rem;
  background: #0b0c2a;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}
.bulk_selection_page {
  display: flex;
  flex-direction: column;
  background: #322d2d;
  width: 50%;
  padding: 1rem;
  color: #fff;
  justify-content: space-between;
  border-radius: 0.5rem;
  height: 200px;
}
.bulk_heed h2 {
  font-size: 1.25rem;
  font-weight: 400;
}
.standard_case {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.special_case {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.size_value {
  font-size: 1.25rem;
}
.size_value select {
  outline: none;
  border: none;
  font-size: 1rem;
  width: 55px;
  border-radius: 3px;
  padding: 0.5rem;
}
.case_size {
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: space-between;
  align-items: center;
}
.case_input {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}
.input_value input {
  border-radius: 4px;
  border: none;
  outline: none;
  width: 80px;
  font-size: 1rem;
  padding: 0.35rem;
}
.standardquantity_name p {
  font-size: 1rem;
}
.standardquantity_add {
  display: flex;
  align-items: center;
  /* border:3px solid #d4c7ca; */
  padding: 0.5rem 1rem;
  border-radius: 15px;
  justify-content: space-between;
  width: 35%;
  background-color: #fff;
}
.standardquantity_add button {
  border: none;
  background: transparent;

  font-size: 1.75rem;
}
.standardquantity_add input {
  width: 12%;
  border: none;
  background: none;
  outline: none;
  font-size: 1rem;
}
.standard_quantity {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}
.bulk_selection {
  display: flex;
  flex-direction: column;
  background: #322d2d;
  width: 50%;
  padding: 1rem;
  color: #fff;
  justify-content: space-between;
  border-radius: 0.5rem;
  height: 300px;
}
.specialcase_name {
  font-size: 1.25rem;
}
.top_head {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.special_headname h3 {
  font-size: 1.5rem;
  font-weight: 700;
}
.popup_page {
  position: absolute;
  right: 3rem;
  bottom: 2rem;
  width: 300px;
  background: #000000;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #fff;
  text-align: center;
  justify-content: center;
  color: #fdfffd;
  border-radius: 5px;
  line-height: 0;
}
.popup_sucess {
  font-size: 1.75rem;
  color: green;
}
.popup_cart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}
.popup p {
  font-size: 0.9rem;
}
.cart_title p {
  font-size: 0.9rem;
}
.cart_icon {
  font-size: 0.9rem;
}
.selectQuantity {
  display: none;
}
.mobSize {
  display: none;
}
.selection_catogory {
}
.catogory_drop {
  display: flex;
  width: 120%;
  justify-content: space-between;
  align-items: center;
}
.catogory_drop h3 {
  font-size: 1.25rem;
  font-weight: 400;
}
.catogory_drop select {
  width: 185px;
  border: 1px solid #4d4dad;
  height: 30px;
  border-radius: 6px;
  font-size: 1rem;
  text-align: center;
  padding: 0.25rem;
  color: #86350c;
  outline: #64f142;
}
.mob_catogory {
  display: none;
}
.special_page{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 275px;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .container {
    width: 100%;
    height: 90vh;
    background-color: #ffffff;
    font-family: "poppins", sans-serif;
    display: flex;
    flex-direction: column;
  }
  .selection_page {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  .selection_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .product_name {
    border-bottom: 2px solid #34343d;
    width: 100%;
  }
  .product_name h2 {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .section_selection {
    margin: 1rem;
    padding: 0.5rem;
  }
  .section_selection h3 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .catogry {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5rem 0;
    width: 100%;
    justify-content: space-between;
  }
  .item_card {
    width: 48%;
    height: 150px;
    background: #e7e4e4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 1rem;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
  .item_top {
    font-size: 50px;
  }
  .item_bottom p {
    font-size: 0.85rem;
  }
  .bulkitem_card {
    width: 48%;
    height: 150px;
    background: #c3c0c0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 1rem;
    margin: 1rem;
    transition: all 0.2s ease-in-out;
  }
  .bulkitem_top {
    font-size: 40px;
  }
  .bulkitem_bottom p {
    font-size: 0.85rem;
  }
  .item_selection {
    display: flex;
    flex-direction: column;
    padding: 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  .chart_head {
    display: flex;
    flex-direction: column;
    height: 200px;
    background: #322d2d;
    width: 100%;
    padding: 1rem;
    color: #fff;
    justify-content: space-between;
    border-radius: 0.5rem;
  }

  .size_name h2 {
    font-size: 1rem;
    font-weight: 400;
  }
  .size_chart {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
  }
  .size_chart button {
    border: none;
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 30px;
    width: 35px;
    background-color: #fff;
  }
  .quantity {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .quantity_name p {
    font-size: 1rem;
  }
  .quantity_add {
    display: flex;
    align-items: center;
    /* border: 3px solid #d4c7ca; */
    padding: 0.5rem 1rem;
    border-radius: 15px;
    justify-content: space-between;
    width: 30%;
    background-color: #fff;
  }
  .quantity_add button {
    border: none;
    background: transparent;

    font-size: 1.25rem;
  }
  .quantity_add input {
    width: 30%;
    border: none;
    background: none;
    outline: none;
    font-size: 1.25rem;
  }
  .section_bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .cancel button {
    border: none;
    width: 120px;
    height: 35px;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #111225;
    font-size: 1rem;
    padding: 0rem;
    cursor: pointer;
  }
  .cart_button button {
    border: none;
    width: 120px;
    height: 40px;
    border-radius: 0.5rem;
    background: #111225;
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem;
    cursor: pointer;
  }
  .bulk_selection_page {
    display: flex;
    flex-direction: column;
    height: 300px;
    background: #322d2d;
    width: 100%;
    padding: 0.75rem;
    color: #fff;
    justify-content: space-between;
    border-radius: 0.5rem;
  }

  .bulk_heed h2 {
    font-size: 1rem;
    font-weight: 400;
  }
  .bulk_selection_page h3 {
    font-size: 1.05rem;
  }

  .standard_case {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .case_name p {
    font-size: 0.75rem;
  }
  .case_value {
    font-size: 0.75rem;
  }

  .special_case {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .size_value select {
    outline: none;
    border: none;
    font-size: 0.75rem;
    width: 50px;
    border-radius: 3px;
    padding: 0.15rem;
  }
  .case_size {
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: space-between;
    align-items: center;
  }
  .case_input {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }
  .input_value input {
    border-radius: 4px;
    border: none;
    outline: none;
    width: 60px;
    font-size: 0.75rem;
    padding: 0.15rem;
  }
  .standardquantity_name p {
    font-size: 0.75rem;
  }
  .standardquantity_add {
    display: flex;
    align-items: center;
    /* border: 3px solid #d4c7ca; */
    padding: 0.5rem 0.75rem;
    border-radius: 15px;
    justify-content: space-between;
    width: 50%;
    background-color: #fff;
  }
  .standardquantity_add button {
    border: none;
    background: transparent;

    font-size: 1.2rem;
  }
  .standardquantity_add input {
    width: 20%;
    border: none;
    background: none;
    outline: none;
    font-size: 15px;
  }
  .standard_quantity {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }

  .bulk_cancel button {
    border: none;
    width: 80px;
    height: 30px;
    border-radius: 0.25rem;
    background: #ffffff;
    color: #111225;
    font-size: 12px;
    padding: 0.5rem;
    cursor: pointer;
  }
  .bulkcart_button button {
    border: none;
    width: 95px;
    height: 30px;
    border-radius: 0.25rem;
    background: #111225;
    color: #fff;
    font-size: 0.75rem;
    padding: 0.5rem;
    cursor: pointer;
  }
  .selectQuantity {
    display: block;
    font-size: 15px;
  }
  .webQuantity {
    display: none;
  }
  .webSize {
    display: none;
  }
  .mobSize {
    display: block;
    font-size: 15px;
  }
  .web_catogory {
    display: none;
  }
  .mob_catogory {
    display: block;
  }
  .new_standard {
    display: flex;
    flex-direction: column;

    background: #322d2d;
    width: 100%;
    padding: 1rem;
    color: #fff;
    justify-content: space-between;
    border-radius: 0.5rem;
  }
  .new_head h2 {
    font-size: 1rem;
  }
  .nw_cancel button {
    border: none;
    width: 90px;
    height: 30px;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #111225;
    font-size: 0.75rem;
    cursor: pointer;
  }
  .bulkcart_new button {
    border: none;
    width: 90px;
    height: 30px;
    border-radius: 0.5rem;
    background: #111225;
    color: #fff;
    font-size: 0.75rem;
    cursor: pointer;
  }
  .catogory_drop {
    display: flex;
    width: 120%;
    justify-content: space-between;
    align-items: center;
  }
  .catogory_drop h3 {
    font-size: 1rem;
    font-weight: 400;
  }
  .catogory_drop select {
    width: 150px;
    border: 1px solid #e7123b;
    border-radius: 6px;
    font-size: 15px;
    color: #193ec3;
  }
  .bulk_selection {
    display: flex;
    flex-direction: column;
    background: #322d2d;
    width: 100%;
    padding: 0.75rem;
    color: #fff;
    justify-content: space-between;
    border-radius: 0.5rem;
    height: 300px;
  }
  .special_headname h3 {
    font-size: 1rem;
    font-weight: 700;
  }
  .specialcase_name p{
    font-size: 1rem;
  }
  .size_value {
    font-size: 15px;
  }
  .special_page{
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: space-between;
  }
}

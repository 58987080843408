.container {
  width: 100%;
  height: 100%;
  font-family: "poppins", sans-serif;
}
.profile_page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 3rem 4rem;
}
.prof_head {
  border-bottom: 1px solid #362b2b;
  width: 30%;
}
.prof_head h1 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #3c1111;
}
.profile_Name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  margin: 0.5rem 0;
}
.first_name {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.first_name label {
  font-size: 1.25rem;
  font-size: 1.25rem;
  padding: 1rem 0;
}
.first_name input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
}
.second_name {
  display: flex;
  flex-direction: column;
}
.second_name label {
  font-size: 1.25rem;
  font-size: 1.25rem;
  padding: 1rem 0;
}
.second_name input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
}
.information {
  display: flex;
  flex-direction: column;
}
.information label {
  font-size: 1.25rem;
  font-size: 1.25rem;
  padding: 1rem 0;
}
.information input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
  width: 550px;
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30%;
}
.city {
  display: flex;
  flex-direction: column;
}
.city label {
  font-size: 1.25rem;
  padding: 1rem 0;
}
.city input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
}
.state {
  display: flex;
  flex-direction: column;
}
.state label {
  font-size: 1.25rem;
  padding: 1rem 0;
}
.state input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
}
.second_location {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30%;
}
.pincode {
  display: flex;
  flex-direction: column;
}
.pincode label {
  font-size: 1.25rem;
  padding: 1rem 0;
}
.pincode input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
}
.country {
  display: flex;
  flex-direction: column;
}
.country label {
  font-size: 1.25rem;
  padding: 1rem 0;
}
.country input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
}

.submitt button {
  border: none;
  background: black;
  color: #fff;
  width: 100px;
  height: 50px;
  border-radius: 5px;
  padding: 0.25rem;
  font-size: 1rem;
  margin: 1rem 0;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .profile_page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0.25rem 1.5rem;
  }
  .prof_head {
    border-bottom: 1px solid #362b2b;
    width: 100%;
  }
  .prof_head h1 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #3c1111;
  }
  .profile_Name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.75rem 0;
  }
  .first_name {
    width: 49%;
  }
  .first_name label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .second_name {
    width: 49%;
  }
  .second_name label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .information {
    width: 100%;
  }
  .information label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .information input {
    outline: none;
    padding: 0.5rem;
    border: 1px solid #aaa2a2;
    border-radius: 5px;
    width: 100%;
  }
  .location {
    width: 100%;
  }
  .city {
    width: 49%;
  }
  .city label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .state {
    width: 49%;
  }
  .state label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .second_location {
    width: 100%;
  }
  .pincode {
    width: 49%;
  }
  .pincode label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .country {
    width: 49%;
  }
  .country label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .submitt {
    margin-left: auto;
    margin-right: auto;
  }
  .submitt button {
    border: none;
    background: black;
    color: #fff;
    width: 250px;
    height: 45px;
    border-radius: 5px;
    padding: 0.25rem;
    font-size: 1.25rem;
    margin: 1rem 0;
  }
}

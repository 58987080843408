.container {
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(250, 244, 244, 1) 0%,
    rgba(252, 116, 118, 0.42620798319327735) 47%,
    rgba(255, 239, 239, 1) 100%,
    rgba(208, 195, 195, 1) 100%
  ); */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}
.left {
  width: 50%;
  height: 100%;

  /* border: 2px solid #fff; */

  padding: 35px;
}
.left img {
  border-radius: 30px 0 0 30px;
  width: 100%;
  height: 100%;
}

.right {
  padding: 35px;
  /* background-color: #f3fff0; */
  width: 50%;
  height: 100%;
  /* border-radius: 0 25px 25px 0; */
  border-left: 0px;
  /* border: 2px solid #fff; */
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.form_border {
  /* border: 1px solid #de4b6e; */
  /* border-radius: 10px; */
  width: 100%;
  height: 100%;
  /* position: absolute; */
  /* top: 20%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form_border h1 {
  text-align: center;
  color: #18191f;
  font-size: 30px;
}
.form_border p {
  font-size: 20px;
  text-align: center;
  color: #4e524e;
}
.register_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.5rem;
  width: 100%;
}
.email {
  margin: 0.5rem 0;
  text-align: left;
  font-family: "Poppins", sans-serif;
  border: 1px solid #3988f7;
  font-weight: 500;
  padding: 1rem;
  font-size: 1.25rem;
  border-radius: 15px;
  width: 50%;
  outline: none;
  display: flex;
  justify-content: space-between;
}
.icons {
  color: #3a71f2;
}
.email input {
  text-align: left;
  font-family: "Poppins", sans-serif;

  font-weight: 500;

  font-size: 1.25rem;
  width: 100%;
  outline: none;
  border: none;
}
.password {
  margin: 0.5rem 0;
  text-align: left;
  font-family: "Poppins", sans-serif;
  border: 1px solid #3988f7;
  font-weight: 500;
  padding: 1rem;
  font-size: 1.25rem;
  border-radius: 15px;
  width: 50%;
  outline: none;
  display: flex;
  justify-content: space-between;
}
.password input {
  text-align: left;
  font-family: "Poppins", sans-serif;

  font-weight: 500;

  font-size: 1.25rem;
  width: 100%;
  outline: none;
  border: none;
}
.register_form input::-webkit-input-placeholder {
  color: #3a71f2;
}
.register_form button {
  padding: 14px;
  border: none;
  margin: 0.5rem;
  width: 38%;
  border-radius: 5px;
  background: #283fbf;
  color: #fff;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  border: none;
  outline: none;
  font-weight: 500;
}
.register_form p {
  font-size: 15px;
  text-align: end;
}
.reset {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.remember {
  text-align: start;
  color: #4e524e;
}
.remember label {
  padding: 0.25rem;
}

.forgot {
  text-align: start;
  color: #4e524e;
}
.register {
  display: flex;
}
.register p {
  font-size: 15px;
  color: #4e524e;
  padding: 0.25rem;
}
.register button {
  border: none;
  color: #1b0fbf;
  background: none;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "Poppins", sans-serif;
  }
  .left {
    width: 0;
    height: 100%;
    padding: 0;
  }
  .right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .form_border {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .form_border h1 {
    text-align: center;
    color: #18191f;
    font-size: 30px;
  }
  .form_border p {
    font-size: 20px;
    text-align: center;
    color: #4e524e;
  }
  .register_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.25rem;
    width: 100%;
  }
  .email {
    margin: 1rem 0;
    text-align: left;
    font-family: "Poppins", sans-serif;
    border: 1px solid #3988f7;
    font-weight: 500;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: 15px;
    width: 100%;
    outline: none;
    display: flex;
    justify-content: space-between;
  }
  .icons {
    color: #3a71f2;
  }
  .register_form input {
    text-align: left;
    font-family: "Poppins", sans-serif;

    font-weight: 500;

    font-size: 1.25rem;

    outline: none;
    border: none;
  }
  .password {
    margin: 1rem 0;
    text-align: left;
    font-family: "Poppins", sans-serif;
    border: 1px solid #3988f7;
    font-weight: 500;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: 15px;
    width: 100%;
    outline: none;
    display: flex;
    justify-content: space-between;
  }
  .register_form input::-webkit-input-placeholder {
    color: #3a71f2;
  }
  .register_form button {
    padding: 14px;
    border: none;
    margin: 1rem;
    width: 38%;
    border-radius: 5px;
    background: #283fbf;
    color: #fff;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    border: none;
    outline: none;
    font-weight: 500;
  }
  .register_form p {
    font-size: 15px;
    text-align: end;
  }
  .reset {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem;
  }
  .remember {
    text-align: start;
    color: #4e524e;
  }
  .remember label {
    padding: 0.25rem;
  }
  .forgot {
    text-align: start;
    color: #4e524e;
  }
  .register {
    display: flex;
  }
  .register p {
    font-size: 15px;
    color: #4e524e;
    padding: 0.25rem;
  }
  .register button {
    border: none;
    color: #1b0fbf;
    background: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "Poppins", sans-serif;
  }
  .left {
    width: 0;
    height: 100%;
    padding: 0;
  }
  .right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .form_border {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .form_border h1 {
    text-align: center;
    color: #18191f;
    font-size: 50px;
  }
  .form_border p {
    font-size: 35px;
    text-align: center;
    color: #4e524e;
  }
  .register_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.25rem;
    width: 100%;
  }
  .email {
    margin: 2rem 0;
    text-align: left;
    font-family: "Poppins", sans-serif;
    border: 2px solid #3988f7;
    font-weight: 500;
    padding: 1rem;
    font-size: 2.25rem;
    border-radius: 35px;
    width: 100%;
    outline: none;
    display: flex;
    justify-content: space-between;
  }
  .icons {
    color: #3a71f2;
  }
  .register_form input {
    text-align: left;
    font-family: "Poppins", sans-serif;

    font-weight: 500;

    font-size: 2rem;

    outline: none;
    border: none;
  }
  .password {
    margin: 0.75rem 0;
    text-align: left;
    font-family: "Poppins", sans-serif;
    border: 2px solid #3988f7;
    font-weight: 500;
    padding: 1rem;
    font-size: 2.25rem;
    border-radius: 35px;
    width: 100%;
    outline: none;
    display: flex;
    justify-content: space-between;
  }
  .register_form input::-webkit-input-placeholder {
    color: #3a71f2;
  }
  .register_form button {
    padding: 15px;
    border: none;
    margin: 2rem;
    width: 40%;
    border-radius: 5px;
    background: #283fbf;
    color: #fff;
    font-size: 30px;
    font-family: "Poppins", sans-serif;
    border: none;
    outline: none;
    font-weight: 500;
  }
  .register_form p {
    font-size: 15px;
    text-align: end;
  }
  .reset {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem;
  }
  .remember {
    text-align: start;
    color: #4e524e;
  }
  .remember label {
    padding: 0.25rem;
    font-size: 1.75rem;
  }

  .forgot p {
    text-align: start;
    color: #4e524e;
    font-size: 1.75rem;
  }
  .register {
    display: flex;
  }
  .register p {
    font-size: 35px;
    color: #4e524e;
    padding: 0.25rem;
  }
  .register button {
    border: none;
    color: #1b0fbf;
    background: none;
    font-size: 35px;
  }
}

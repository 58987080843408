.container {
  height: 100%;
  width: 100%;
  font-family: "poppins", sans-serif;
  /* background-color: #efefef; */
}
.detail_page {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}
.detail_head {
  border-bottom: 1px solid #b0b0b0;
}
.detail_head h1 {
  font-weight: 500;
}
.order_one {
  display: flex;
  flex-direction: column;
  margin: 3rem;
}
.one_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b0b0b0;
}
.head_left h1 {
  font-size: 1.75rem;
  font-weight: 400;
}
.head_right{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.head_right p {
  font-size: 1.25rem;
}
.orders {
  display: flex;
  flex-direction: column;
}
.item_one {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}
.item_number {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 15%;
}
.number_up p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.number_bottom p {
  font-size: 1.25rem;
  color: #000000;
}
.item_name {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.name_up p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.name_bottom p {
  font-size: 1.25rem;
  color: #000000;
}
.item_quantity {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.quantity_up p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.quantity_bottom p {
  font-size: 1.25rem;
  color: #000000;
}
.item_price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 15%;
}
.price_up p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.price_bottom p {
  font-size: 1.25rem;
  color: #000000;
  /* padding: 0.75rem 0; */
}
.item_tottal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 15%;
}
.tottal_up p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.tottal_bottom p {
  font-size: 1.25rem;
  color: #000000;
}
.item_status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 20%;
}
.status_up p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.status_bottom p {
  font-size: 1.25rem;
  color: #000000;
}
.price {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20%;
  padding: 3rem 0;
}
.subtotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.subtotal_left p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.subtotal_right {
  font-size: 1.25rem;
  color: #000000;
}
.shipping {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.shipping_left p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.shipping_right p {
  font-size: 1.25rem;
  color: #000000;
}
.tax {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
}
.tax_left p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.tax_right {
  font-size: 1.25rem;
  color: #000000;
}
.grant_total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}
.total_left {
  font-size: 1.5rem;
  color: #7f7d7d;
}
.total_right {
  font-size: 1.5rem;
  color: #000000;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .detail_page {
    display: flex;
    flex-direction: column;
    margin: 0.75rem;
  }
  .detail_head h1 {
    font-weight: 500;
    font-size: 1.5rem;
  }
  .order_one {
    display: flex;
    flex-direction: column;
    margin: 0.75rem 0.25rem;
  }

  .head_left h1 {
    font-size: 1rem;
    font-weight: 400;
  }
  .head_right p {
    font-size: 1rem;
  }
  .orders {
    overflow-x: scroll;
  }
  .item_one {
    display: flex;
    flex-direction: row;
    width: fit-content;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }
  .item_number {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 75px;
  }
  .number_up p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .number_bottom p {
    font-size: 1rem;
    color: #000000;
  }

  .item_name {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .name_up p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .name_bottom p {
    font-size: 1rem;
    color: #000000;
  }
  .item_quantity {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .quantity_up p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .quantity_bottom p {
    font-size: 1rem;
    color: #000000;
  }
  .item_price {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100px;
  }
  .price_up p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .price_bottom p {
    font-size: 1rem;
    color: #000000;
    /* padding: 0.75rem 0; */
  }
  .item_tottal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100px;
  }
  .tottal_up p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .tottal_bottom p {
    font-size: 1rem;
    color: #000000;
  }
  .item_status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100px;
  }
  .status_up p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .status_bottom p {
    font-size: 1rem;
    color: #000000;
  }
  .price {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 75%;
    padding: 1rem 0;
  }
  .subtotal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .subtotal_left p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .subtotal_right {
    font-size: 1rem;
    color: #000000;
  }
  .shipping {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .shipping_left p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .shipping_right p {
    font-size: 1rem;
    color: #000000;
  }
  .tax {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
  }
  .tax_left p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .tax_right {
    font-size: 1rem;
    color: #000000;
  }
  .total_left {
    font-size: 1.15rem;
    color: #7f7d7d;
  }
  .total_right {
    font-size: 1.15rem;
    color: #000000;
  }
}

.container{
    width: 100%;
    height: 90vh;
    background-color: #f5f2ff;
    font-family: "poppins", sans-serif;
}
.search_page{
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
}
.product_card{
    width: 350px;
    height: 350px;
    /* border: 1px solid #f1edf4; */
    margin: 1.5rem 1rem;
    padding: 1.25rem;
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: all 0.2s ease-in-out;
}
.product_card:hover {
    margin-top: 3px;
    box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
  }
.product_image{
    width: 60%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}
.card_body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    align-items: flex-start;
}
.product_description{
    display: flex;
    flex-direction: column;
  
    justify-content: center;
}
.product_name p{
    font-family: "poppins";
    font-size: 1.5rem;
    color: #24172d;
    font-weight: 600;
}
.product_rate p{
    font-family: "poppins";
    font-size: 1.25rem;
    color: #24172d;
}
.cart{
    font-size: 1.75rem;
    cursor: pointer;
}
.product_firstrow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.container {
  width: 100%;
  height: 90vh;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
}
.container::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.home {
  padding: 1rem;
}
.add {
  width: 100%;
  height: 500px;
  background: #ffe2b5;
  border-radius: 3rem;
  display: flex;
  flex-direction: row;
}
.ad_left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  align-items: flex-start;
  padding: 5.5rem;
}
.left_write {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  /* height: 100%; */
}
.left_write h1 {
  font-weight: 400;
  font-family: "poppins";
  font-size: 3.5rem;
}
.left_write h2 {
  font-size: 3.5rem;
  font-weight: 800;
  font-family: "poppins";
}
.left_write p {
  font-size: 1.5rem;
  font-family: "poppins";
  color: #161a44;
}
.left_bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  width: 180px;
  height: 65px;
  background: black;
  color: gold;
  padding: 0.5rem;
  font-size: 1.5rem;
}

.left_bottom button {
  border: none;
  background: black;
  color: gold;
  padding: 0.5rem;
  font-size: 1.5rem;
}
.left_bottom:hover {
  background-color: rgb(51, 46, 46);
}
.left_bottom button:hover {
  background-color: rgb(51, 46, 46);
}
.ad_right {
  width: 100%;
  height: 100%;
  position: relative;
}
.ad_right img {
  width: 70%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  right: 0;
}
.brand {
  margin: 1.5rem 0;
}
.brand h2 {
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 500;

  color: #0c0101;
  -webkit-clip-path: polygon(
    0 0,
    0 0,
    100% 0,
    100% 0,
    100% calc(100% - 15px),
    calc(100% - 15px) 100%,
    15px 100%,
    0 100%
  );
  clip-path: polygon(
    0 0,
    0 0,
    100% 0,
    100% 0,
    100% calc(100% - 15px),
    calc(100% - 15px) 100%,
    15px 100%,
    0 100%
  );
  height: 40px;
  font-size: 1.25rem;
  transition: 0.2s 0.1s;
  background-image: linear-gradient(90deg, #d6c12b, #e6bece);
  border: 0 solid;
  padding: 0.25rem 0.75rem;
  width: 250px;
}
.brand h2:hover {
  cursor: pointer;
  transition: all 0.3s ease-in;
  padding-right: 30px;
  padding-left: 30px;
}
.brand_cards {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1rem;
  margin: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}
.card_page {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 1rem;
  border: 2px solid #e3e3e3;
  justify-content: space-around;
  cursor: pointer;
}
.card_page h1 {
  padding: 0.5rem 0;
  font-size: 1.25rem;
  font-weight: 500;
}
.card {
  width: 25%;
  transition: all 0.5s;
  color: black;
}

.card:hover {
  transform: scale(1.05);
}

.card:active {
  transform: scale(0.95) rotateZ(1.7deg);
}
.card img {
  width: 100%;
  height: 100%;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .add {
    width: 100%;
    height: 250px;
    background: #ffe2b5;
    border-radius: 3rem;
    display: flex;
    flex-direction: row;
  }
  .ad_left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    align-items: flex-start;
    padding: 1.5rem;
  }
  .left_write {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    /* height: 100%; */
  }
  .left_write h1 {
    font-weight: 400;
    font-family: "poppins";
    font-size: 1.25rem;
  }
  .left_write h2 {
    font-size: 1.5rem;
    font-weight: 800;
    font-family: "poppins";
  }
  .left_write p {
    font-size: 1.25rem;
    font-family: "poppins";
    color: #161a44;
  }
  .left_bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    width: 150px;
    height: 50px;
    background: black;
    color: gold;
    padding: 0.25rem;
    font-size: 1.25rem;
  }
  .left_bottom button {
    font-size: 1rem;
  }
  .ad_right {
    width: 0;
    height: 0;
    position: static;
  }
  .ad_right img {
    width: 70%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    right: 0;
    display: none;
  }
  .brand h2 {
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
    width: 180px;
  }
  .brand_cards {
    margin: 1rem 1rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .card_page {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 15px;
    margin: 15px;
    border-radius: 1rem;
    border: 2px solid #e3e3e3;
  }
  .card_page h1 {
    padding: 0.25rem;
    font-size: 1rem;
    font-weight: 500;
  }
  .card {
    width: 85%;
    height: 100%;
    cursor: pointer;
    transition: all 0.5s;
    color: black;
  }
}

.container {
    /* background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(250, 244, 244, 1) 0%,
      rgba(252, 116, 118, 0.42620798319327735) 47%,
      rgba(255, 239, 239, 1) 100%,
      rgba(208, 195, 195, 1) 100%
    ); */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "Poppins", sans-serif;
  }
  .left {
    width: 50%;
    height: 100%;
  
    /* border: 2px solid #fff; */
   
    padding: 35px;
   
  }
  .left img {
    border-radius: 30px 0 0 30px;
    width: 100%;
    height: 100%;
  }
  
  .right {
      padding: 35px;
    /* background-color: #f3fff0; */
    width: 50%;
    height: 100%;
    /* border-radius: 0 25px 25px 0; */
    border-left: 0px;
    /* border: 2px solid #fff; */
    /* position: relative; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .form_border {
    /* border: 1px solid #de4b6e; */
    /* border-radius: 10px; */
    width: 100%;
    height: 100%;
    /* position: absolute; */
    /* top: 20%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .form_border h1 {
    text-align: center;
    color: #18191f;
    font-size: 30px;
  }
  .form_border p {
    font-size: 20px;
    text-align: center;
    color: #4e524e;
  }
  .register_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1.5rem;
    width: 100%;
  }
  .email {
    margin: 0.5rem 0;
    text-align: left;
    font-family: "Poppins", sans-serif;
    border: 1px solid #3988f7;
    font-weight: 500;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: 15px;
    width: 50%;
    outline: none;
    display: flex;
    justify-content: space-between;
  }
  .icons{
      color: #3a71f2;
  }
  .register_form input {
    text-align: left;
    font-family: "Poppins", sans-serif;
  
    font-weight: 500;
  
    font-size: 1.25rem;
  
    outline: none;
    border: none;
  
   
  }
  .password {
    margin: 0.5rem 0;
    text-align: left;
    font-family: "Poppins", sans-serif;
    border: 1px solid #3988f7;
    font-weight: 500;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: 15px;
    width: 50%;
    outline: none;
    display: flex;
    justify-content: space-between;
  }
  .register_form input::-webkit-input-placeholder {
    color: #3a71f2;
  }
  .register_form button {
    padding: 14px;
    border: none;
    margin: 0.5rem;
    width: 38%;
    border-radius: 5px;
    background: #283fbf;
    color: #fff;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    border: none;
    outline: none;
    font-weight: 500;
  }
  .register_form p{
      font-size: 15px;
      text-align: end;
  }
  .reset{
  display: flex;
  justify-content: space-between;
  width: 50%;
  }
  .remember{
  
    text-align: start;
    color: #4e524e;
  }
  .remember label{
    padding: 0.25rem;
  }
  .forgot{
   
    text-align: start;
    color: #4e524e;
  }
  .register{
      display: flex;
  }
  .register p{
      font-size: 15px;
      color: #4e524e;
      padding: 0.25rem;
  }
  .register button{
      border: none;
      color: #1b0fbf;
      background: none;
  }
  @media only screen and (max-width: 600px) {
    
  }
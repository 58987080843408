.container {
  height: 100%;
  width: 100%;
  font-family: "poppins", sans-serif;
}
.checkout_page {
  display: flex;
  flex-direction: row;
  margin: 2rem;
}
.checkout {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.shipping_address {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 1.5rem;
}
.shipping_address h1 {
  font-size: 1.5rem;
  font-weight: 500;
}
.shipping_head {
  display: flex;
  flex-direction: column;
}
.shipping_head label {
  padding: 1.25rem 0;
}
.shipping_head input {
  /* width: 50%; */
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.email {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
}
.email_address {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.email_address label {
  padding: 1.25rem 0;
}
.email_address input {
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.conf_email {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.conf_email label {
  padding: 1.25rem 0;
}
.conf_email input {
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.number {
  display: flex;
  flex-direction: column;
}
.number label {
  padding: 1.25rem 0;
}
.number input {
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.places {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.city {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.city label {
  padding: 1.25rem 0;
}
.city input {
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.region {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.region label {
  padding: 1.25rem 0;
}
.region input {
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.post_code {
  display: flex;
  flex-direction: column;
}
.post_code label {
  padding: 1.25rem 0;
}
.post_code input {
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.shipping_method {
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  margin: 2rem 0;
  padding: 1.5rem;
}
.method_head h1 {
  font-size: 1.5rem;
  font-weight: 500;
}
.method_one {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  border: 1px solid #dbdbdb;
  padding: 1rem;
  border-radius: 5px;
}

.one_left {
  display: flex;
  flex-direction: row;
  width: 25%;
  font-size: 1.25rem;
}
.one_left p {
  padding: 0 0.25rem;
}
.one_right {
  font-size: 1.25rem;
}
.order {
  width: 45%;
  height: 40%;
  margin: 0 1rem;
}
.ordera_summory {
  display: flex;
  flex-direction: column;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  padding: 1rem;
}
.order_head h1 {
  font-size: 1.5rem;
  font-weight: 500;
}
.subtotal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}
.subtotal_left {
  color: #bab5b5;
}
.subtotal_right p {
  font-size: 1.15rem;
}

.Discount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  color: #bab5b5;
}

.shippment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  color: #bab5b5;
}
.total {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
}
.total_left p {
  font-size: 1.15rem;
}
.total_right p {
  font-size: 1.5rem;
}
.payment_button {
  margin-left: auto;
  margin-right: auto;
}
.payment_button button {
  border: none;
  border-radius: 5px;
  padding: 0.25rem;
  font-size: 1rem;
  width: 200px;
  height: 40px;
  color: black;
  background-color: #cdcdcd;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .checkout_page {
    display: flex;
    flex-direction: column;
    margin: 1rem;
  }
  .checkout {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .shipping_address {
    padding: 1rem;
  }
  .shipping_address h1 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .shipping_head label {
    padding: 1rem 0;
  }

  .email {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0;
  }
  .email_address {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .email_address label {
    padding: 1rem 0;
  }
  .conf_email {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .conf_email label {
    padding: 1rem 0;
  }
  .number label {
    padding: 1rem 0;
  }
  .city {
    display: flex;
    flex-direction: column;
    width: 49%;
  }
  .city label {
    padding: 1rem 0;
  }
  .region {
    display: flex;
    flex-direction: column;
    width: 49%;
  }
  .region label {
    padding: 1rem 0;
  }
  .post_code label {
    padding: 1rem 0;
  }
  .shipping_method {
    border-radius: 5px;
    border: 1px solid #c3c3c3;
    margin: 1rem 0;
    padding: 1.25rem;
  }
  .method_head h1 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .method_one {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1rem 0;
    border: 1px solid #dbdbdb;
    padding: 1rem;
    border-radius: 5px;
  }

  .one_left {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 1rem;
  }
  .one_left p {
    padding: 0 0.25rem;
  }
  .one_right {
    font-size: 1rem;
  }
  .order {
    width: 100%;
    height: 40%;
    margin: 0;
  }
  .order_head h1 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .subtotal_right p {
    font-size: 1rem;
  }
  .total_left p {
    font-size: 1.25rem;
  }
  .total_right p {
    font-size: 1.25rem;
  }
  .payment_button button {
    border: none;
    border-radius: 5px;
    padding: 0.25rem;
    font-size: 0.95rem;
    width: 200px;
    height: 35px;
    color: black;
    background-color: #cdcdcd;
  }
}

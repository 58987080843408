.container {
  width: 100%;
  height: 100%;
  font-family: "poppins", sans-serif;
}
.cart_page {
  display: flex;
  flex-direction: column;
}
.page_top {
  display: flex;
  flex-direction: row;
  margin: 2rem;
}
.cart_image {
  width: 50%;
  height: 50%;
}
.cart_page img {
  width: 50%;
  height: 100%;
}
.cart_product {
  width: 50%;
  margin: 0.75rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product_name {
  text-align: start;
  font-family: "poppins";
}
.product_name h1 {
  font-weight: 300;
  font-size: 1.75rem;
}
.product_name h2 {
  font-size: 1.25rem;
}
.detail {
  display: flex;
  flex-direction: row;
  margin: 0.75rem;
  align-items: center;
  width: 50%;
  justify-content: space-between;
}
.rating {
  font-size: 1.75rem;
  color: gold;
  width: 40%;
}
.review {
  width: 50%;
  text-decoration: underline;
  font-size: 1.25rem;
  font-family: "poppins";
}
.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.quantity {
  display: flex;
  align-items: center;
  border: 1px solid black;
  width: 80px;
  padding: 0.5rem 0.75rem;
  border-radius: 15px;
  justify-content: space-between;
}
.quantity button {
  border: none;
  background: transparent;
  font-size: 1.5rem;
}
.quantity input {
  border: none;
  background: transparent;
  width: 30px;
  text-align: center;
  outline: none;
}
.price {
  font-size: 1.5rem;
  padding: 1.25rem 0;
}
.size_chart {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.chart_head h1 {
  font-size: 1.5rem;
  font-weight: 500;
}
.chart_button {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0.75rem 0;
}
.chart_button button {
  background: transparent;
  border: 1px solid rgb(123, 123, 123);
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
  border-radius: 20px;
  padding: 0.25rem;
}
.order {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
.buy button {
  background: transparent;
  border: 1px solid black;
  width: 170px;
  height: 50px;
  font-size: 1.25rem;
  padding: 0.75rem;
  border-radius: 5px;
}
.cart {
  width: 170px;
  height: 50px;
  font-size: 1.25rem;
  padding: 0.75rem;
  background-color: black;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}
.cart_text button {
  border: none;
  background: transparent;
  font-size: 1.25rem;
  color: #fff;
}
.page_bottom {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}
.page_bottom h1 {
  font-size: 1.5rem;
  color: rgb(46, 28, 63);
}
.related_product {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.card {
  width: 300px;
  height: 250px;
  background-color: rgb(235, 243, 244);
  margin: 1rem;
  border-radius: 15px;
  /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
}
.card_head {
  width: 100%;
  height: 50%;
}
.card_head img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.card_body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 1rem;
}
.card_left {
  display: flex;
  flex-direction: column;
}
.card_right {
  font-size: 1.25rem;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .page_top {
    display: flex;
    flex-direction: column;
    margin: 2rem;
  }
  .cart_image {
    width: 100%;
    height: auto;
  }
  .cart_page img {
    width: 100%;
    height: 100%;
  }
  .cart_product {
    width: 100%;
    margin: 0.75rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .product_name h1 {
    font-weight: 300;
    font-size: 1.25rem;
  }
  .product_name h2 {
    font-weight: 500;
  }
  .detail {
    display: flex;
    flex-direction: row;
    margin: 0.75rem 0;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .rating {
    font-size: 1.5rem;
    color: gold;
    width: 50%;
  }
  .review {
    width: 50%;
    text-decoration: underline;
    font-size: 1rem;
    font-family: "poppins";
  }
  .description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .quantity {
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: 80px;
    padding: 0.5rem 0.75rem;
    border-radius: 15px;
    justify-content: space-between;
  }
  .quantity button {
    border: none;
    background: transparent;
    font-size: 1.25rem;
  }
  .quantity input {
    border: none;
    background: transparent;
    width: 30px;
    text-align: center;
    outline: none;
  }
  .price {
    font-size: 1.25rem;
    padding: 0;
    margin: 0 2.25rem;
  }
  .size_chart {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin: 1.25rem 0;
  }
  .chart_head h1 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .chart_button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0.75rem 0;
  }
  .chart_button button {
    background: transparent;
    border: 1px solid rgb(123, 123, 123);
    width: 40px;
    height: 40px;
    font-size: 1rem;
    border-radius: 20px;
    padding: 0.25rem;
  }

  .order {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .buy button {
    background: transparent;
    border: 1px solid black;
    width: 135px;
    height: 45px;
    font-size: 1rem;
    padding: 0.75rem;
    border-radius: 5px;
  }
  .cart {
    width: 135px;
    height: 47px;
    font-size: 1rem;
    padding: 0.75rem;
    background-color: black;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
  }
  .cart_text button {
    border: none;
    background: transparent;
    font-size: 1rem;
    color: #fff;
  }
  .page_bottom {
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
  }
  .page_bottom h1 {
    font-size: 1.25rem;
    color: rgb(46, 28, 63);
  }
  .related_product {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 0%;
  }

  .card {
    width: 295px;
    height: 215px;
    background-color: rgb(235, 243, 244);
    margin: 1rem;
    border-radius: 15px;
    /* display: flex;
      flex-direction: column;
      justify-content: space-between; */
  }
  .card_head {
    width: 70%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .card_head img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .card_body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1rem;
  }
  .card_left {
    display: flex;
    flex-direction: column;
  }
  .card_right {
    font-size: 1.25rem;
  }
}

.container {
  width: 100%;
  height: 90vh;
  font-family: "poppins", sans-serif;
  background-color: #fff;
}
.list_page {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
}
.order {
  margin: 1rem;
  border-bottom: 1px solid rgb(0, 0, 51);
}
.order h2 {
  font-size: 2.25rem;
  /* font-weight: 600; */
}
.myorders {
  display: flex;
  flex-direction: column;
}
.order_details {
  background: #f2f2f2;
  width: 100%;
  height: 170px;
  margin: 0.75rem 0;
}
.myorder_head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem;
}
.list_number {
  font-size: 1.5rem;
  font-weight: 600;
  width: 16%;
}
.order_id {
  font-size: 1.5rem;
  font-weight: 600;
  width: 16%;
}
.order_name {
  font-size: 1.5rem;
  font-weight: 600;
  width: 20%;
}
.order_date {
  font-size: 1.5rem;
  font-weight: 600;
  width: 16%;
}
.order_status {
  font-size: 1.5rem;
  font-weight: 600;
  width: 16%;
}
.order_total {
  font-size: 1.5rem;
  font-weight: 600;
  width: 16%;
}
.myorder_bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.5rem;
}
.number {
  font-size: 1rem;
  color: #740000;
  width: 16%;
}
.id {
  font-size: 1rem;
  color: #740000;
  width: 15%;
}
.name {
  font-size: 1rem;
  color: #740000;
  width: 15%;
}
.date {
  font-size: 1rem;
  color: #740000;
  width: 15%;
}
.status {
  font-size: 1rem;
  color: #740000;
  width: 15%;
}
.total {
  font-size: 1rem;
  color: #740000;
  width: 11%;
}
.order_icon {
  font-size: 1.5rem;
  color: #3c2f7d;
  cursor: pointer;
  width: 5%;
}
.icon_suggestion {
  right: 30px;
  font-size: 1.15rem;
  position: absolute;
  color: #d3243f;
}
@media only screen and (max-width: 767px) {
  .list_page {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }
  .order h2 {
    font-size: 2rem;
    font-weight: 600;
  }

  .order_details {
    background: #f2f2f2;
    width: 100%;
    height: 100px;
    margin: 0.5rem 0;
    overflow-x: scroll;
  }
  .myorder_head {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: fit-content; */
    justify-content: space-between;
    padding: 0.5rem;
  }
  .list_number {
    font-size: 0.85rem;
    font-weight: 500;
    width: 12%;
  }
  .order_id {
    font-size: 0.85rem;
    font-weight: 500;
    width: 22%;
  }
  .order_name {
    font-size: 0.85rem;
    font-weight: 500;
    width: 25%;
  }
  .order_date {
    font-size: 0.85rem;
    font-weight: 500;
    width: 25%;
  }
  .order_status {
    font-size: 0.85rem;
    font-weight: 500;
    width: 16%;
  }
  .order_total {
    font-size: 0.85rem;
    font-weight: 500;
    width: 15%;
  }
  .myorder_bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* width: fit-content; */
    padding: 0 0.5rem;
  }
  .number {
    font-size: 0.70rem;
    color: #740000;
    width: 17%;
  }
  .id {
    font-size: 0.70rem;
    color: #740000;
    width: 27%;
  }
  .name {
    font-size: 0.70rem;
    color: #740000;
    width: 23%;
  }
  .date {
    font-size: 0.70rem;
    color: #740000;
    width: 27%;
  }
  .status {
    font-size: 0.70rem;
    color: #740000;
    width: 20%;
  }
  .total {
    font-size: 0.70rem;
    color: #740000;
    width: 22%;
  }
  .order_icon {
    font-size: 1rem;
    color: #3c2f7d;
    cursor: pointer;
    width: 0%;
  }
  .icon_suggestion {
    right: 30px;
    font-size: 1.15rem;
    position: absolute;
    color: #d3243f;
  }
}

.container {
  width: 100%;
  height: 90vh;
  background: #f2f2f2;
  font-family: "poppins", sans-serif;
}
.container::-webkit-scrollbar {
  display: none;
}
.product_page {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
}
.first_product {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}
.head {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 25%;
  padding: 1rem 0;
}
.head h1 {
  font-family: "poppins";
  font-size: 2.5rem;
  font-weight: 300;
  color: #3d1c55;
  border-bottom: 2px solid #1a0d53;
  width: 100%;
}
.productSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}
.product_firstrow {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  margin: 1rem;
  padding: 1rem;
  row-gap: 1rem;
}

.product_card {
  /* width: 20rem; */
  border: 2px solid #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
}
.product_card:hover {
  margin-top: 3px;
  box-shadow: 15px 15px 30px #949292, -15px -15px 30px #ffffff;
}
.cardHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.product_image {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.countSelection {
  display: flex;
  align-items: center;
  width: 55%;
  justify-content: space-evenly;
}
.countSelection button {
  font-size: 1.5rem;
  padding: 0 0.5rem;
  border: 2px solid #4779a5;
  background: none;
  color: #4d6666;
  border-radius: 5px;
  cursor: pointer;

}
.countSelection input {
  width: 50px;
  padding: 0.25rem;
  border: 2px solid #4779a5;
  border-radius: 10px;
  color: #4d6666;
  outline: none;
  text-align: center;
  font-size: 1.25rem;

}
.card_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
}
.product_description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
}
.product_name {
  cursor: pointer;
}
.product_name p {
  font-family: "poppins";
  font-size: 1.25rem;
  color: #542176;
  font-weight: 500;
}
.product_rate p {
  font-family: "poppins";
  font-size: 1.25rem;
  color: #9d0404;
}
.cart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
}
.cart input {
  width: 50%;
  margin: 0;
  transform: scale(1.5);
  cursor: pointer;

}
.buttonsDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddItems {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: 500;
  font-size: 1.5rem;
  color: #000;
  font-family: Poppins;
  text-decoration: underline;
}
.second_product {
  margin: 0 2.25rem;
}
.product_secRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.product_secondCard {
  width: 400px;
  height: 400px;
  /* border: 1px solid #f1edf4; */
  margin: 1.5rem 0;
  padding: 1.25rem;
  background-color: #fff;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: all 0.2s ease-in-out;
}
.product_secondCard:hover {
  margin-top: 3px;
  box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
}
.product_secondImage {
  width: 80%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.product_secondImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cartButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}
.cartButton button {
  width: 90%;
  padding: 0.5rem ;
  border: none;
  font-size: 1.25rem;
  background: #d7d8e0;
  color: #66319c;
  border-radius: 5px;
  cursor: pointer;
}
/* mobile */

@media only screen and (max-width: 767px) {
  .first_product {
    margin: 0.75rem 1rem;
  }
  .head {
    border-bottom: 2px solid #1a0d53;
    width: 100%;
  }
  .head h1 {
    font-family: "poppins";
    font-size: 1.75rem;
    font-weight: 500;
    color: #33273b;
  }
  .product_firstrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .product_card {
    width: 48%;
    height: 220px;
    /* border: 1px solid #f1edf4; */
    margin: 1rem 0;
    padding: 1rem;
    background-color: #fff;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: all 0.2s ease-in-out;
  }

  .product_name p {
    font-family: "poppins";
    font-size: 1rem;
    color: #24172d;
    font-weight: 600;
  }
  .product_rate p {
    font-family: "poppins";
    font-size: 1rem;
    color: #24172d;
  }
  .cart {
    font-size: 1.25rem;
  }
  .product_image {
    width: 70%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  /* .buttonsDiv{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .AddItems{
    background-color: transparent;
    border:none;
    outline: none;
    font-family: 500;
    font-size: 1.5rem;
    color: #000;
    font-family: Poppins;
    text-decoration: underline;
  } */
  .second_product {
    margin: 0 1rem;
  }

  .product_secRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .product_secondCard {
    width: 48%;
    height: auto;
    /* border: 1px solid #f1edf4; */
    margin: 1.5rem 0;
    padding: 1.25rem;
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: all 0.2s ease-in-out;
  }

  .product_secondImage {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

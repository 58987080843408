.container {
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.container::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.navbar {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}
.left {
  padding: 0.5rem;

  width: 25%;
}
.link {
  color: #1a1b24;
  font-size: 1.25rem;
  text-decoration: none;
}
.center {
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.search {
  display: flex;
  background-color: #fff;
  width: 40%;
  justify-content: space-between;
  border: 2px solid #02043c;
  padding: 0.75rem;
  border-radius: 35px;
  align-items: center;
}

.search_text input {
  font-size: 1.25rem;

  outline: none;
  border: none;
  width: 100%;
  color: #04099b;
}
.search_text input::-webkit-input-placeholder {
  color: #15162b;
}
.icons {
  color: #02142d;
  font-size: 1.25rem;
}
.right {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.profile {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 0.5rem;
  font-family: "Poppins";
  width: 50%;
}
.profile p {
  font-size: 1.25rem;
  margin: 0 0.75rem;
  color: #074276;
}
.profile svg {
  font-size: 1.5rem;
  color: #1a1b24;
  cursor: pointer;
}
.cartButton {
  font-size: 1.5rem;
  color: #1a1b24;
  cursor: pointer;
}
.cartButton span {
  position: absolute;
  top: 10px;
  font-size: 1.25rem;
  font-weight: 600;
  color: #99141d;
}
.drop_menu {
  position: absolute;
  top: 5rem;
  right: 5px;
  /* margin: 1rem; */
  background-color: #f3f4f3;
  width: 12rem;
  height: 12rem;
  z-index: 2;
  border: 2px solid #2c302d;
  border-radius: 20px;
}
.drop_item {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* margin: 1.5rem; */
  padding: 1rem;
  justify-content: space-between;
}
.mobile_search {
  display: none;
}
.mobile_search input {
  width: 20%;
}
.myprofile {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 1.1rem;
}
.my_icon {
  width: 30%;
}
.my_prof {
  width: 70%;
}
.my_prof p {
  color: black;
}
.myorder {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 1.1rem;
}
.order_icon {
  width: 30%;
}
.order {
  width: 70%;
}
.order p {
  color: black;
}
.cart {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 1.1rem;
}
.cart_icon {
  width: 30%;
}
.cart_text {
  width: 70%;
}
.cart_text p {
  color: black;
}

.logout {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 1.1rem;
}
.log_icon {
  width: 30%;
}
.log {
  width: 70%;
  cursor: pointer;
}

.search_mobile {
  display: none;
}
.search_popup {
  width: 20%;
  height: auto;
  background-color: #ffffff;
  position: absolute;
  right: 40%;
  border-radius: 5px;
}
.popup_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup_main {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.75rem;
}
.popup_head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  z-index: 5;
  flex-direction: column;
}
.popup_text {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
}
.popup_text p {
  font-size: 1.15rem;
  color: rgb(44 14 14 / 81%);
}
.popup_close {
  font-size: 1.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.chat_icon {
  position: fixed;
  bottom: 50px;
  right: 10px;
  font-size: 2.5rem;
  color: #f5f5ff;
  height: 65px;
  width: 65px;
  background: #0c0446;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat_container {
  position: fixed;
  bottom: 100px;
  right: 100px;
  z-index: 200;
}
.chat_page {
  /* border: 2px solid #272020; */
  width: 400px;
  height: 650px;
  display: flex;
  flex-direction: column;
  /* padding: 1rem; */
  align-items: flex-start;
  justify-content: flex-start;
  /* background: #4595d7;
     color: white; */
  /* border-radius: 5px; */
  overflow: hidden;
}
.chat_head {
  background: #302aa9;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 2rem 0 0 0rem;
  color: #fff;
}
.chat_head h3 {
  font-size: 1.5rem;
  font-weight: 800;
}
.chat_body {
  color: #000;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  border-radius: 0 0 2rem 0;
  overflow: hidden;
  padding: 1rem;
  border: 3px solid #b8c9da;
  border-top: #302aa9;
}
.chat_body label {
  font-size: 1.25rem;
}
.chat_body input {
  border: 1px solid #a7a7cd;
  /* background: #8d9dfe; */
  width: 85%;
  height: 30px;
  outline: none;
  border-radius: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  /* /* border: none; */
}
.chat_name {
  display: flex;
  flex-direction: column;
  height: 40%;
  justify-content: space-between;
}
.chat_number {
  display: flex;
  flex-direction: column;
  height: 40%;
  justify-content: space-between;
}
.chat_button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0;
}
.chat_button button {
  width: 150px;
  height: 45px;
  border: none;
  border-radius: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  background: #393597;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}
.chat_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.chat_box textarea {
  width: 100%;
  height: 80%;
  outline: none;
  border: 1px solid #a7a7cd;
}
/* mobile screen */

@media only screen and (max-width: 767px) {
  .container {
    width: 100%;
    height: 10vh;
    font-family: "Poppins", sans-serif;
  }
  .navbar {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem;
  }
  .left {
    color: #1a1b24;
    font-size: 1rem;
    width: 65%;
    padding: 0.5rem;
  }
  .center {
    width: 0%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .search {
    display: none;
  }

  .right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    width: 17%;
    font-size: 1.15rem;
    font-family: "Poppins";
    color: #1a1b24;
  }
  .profile_text {
    display: none;
  }
  .prof_icon {
    font-size: 1rem;
  }

  .drop_menu {
    position: absolute;
    top: 3rem;
    right: 0;
    margin: 1rem;
    background-color: #e5ffd6;
    width: 50%;
    z-index: 2;
    border: 2px solid #c5d2b8;
    border-radius: 20px;
  }
  .drop_item {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0rem;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
  }
  .mobile_search {
    display: flex;
    padding: 0.5rem 0;
    align-items: center;
    justify-content: space-around;
    width: 75%;
    font-size: 1.25rem;
  }
  .mobsearch_icon {
    font-size: 1.5rem;
  }

  .myprofile {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0.5rem 0;
  }
  .my_icon {
    width: 30%;
  }
  .my_prof {
    width: 70%;
  }
  .my_prof p {
    color: black;
  }
  .myorder {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0.5rem 0;
  }
  .order_icon {
    width: 30%;
  }
  .order {
    width: 70%;
  }
  .order p {
    color: black;
  }
  .cart {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0.5rem 0;
  }
  .cart_icon {
    width: 30%;
  }
  .cart_text {
    width: 70%;
  }
  .cart_text p {
    color: black;
  }

  .logout {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0.5rem 0;
  }
  .log_icon {
    width: 30%;
  }
  .log {
    width: 70%;
  }

  .search_mobile {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    position: fixed;
    z-index: 200;
    top: 0;
  }
  .mobile_fullsearch {
    display: flex;
    background-color: #242222;
    width: 100%;
    justify-content: space-between;
    border: 2px solid #ffffff;
    padding: 0.5rem;
    border-radius: 10px;
    align-items: center;
    z-index: 8;
    position: absolute;
    top: 10px;
  }
  .search_left input {
    width: 330px;
    height: 30px;
    border: none;
    outline: none;
    font-size: 1.25rem;
    color: #fff;
    background-color: #242222;
  }
  .search_right {
    font-size: 1.5rem;
    color: #fff;
  }
  .mobsearch_result {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    align-items: flex-start;
    justify-content: space-around;
    padding: 1rem;
  }
  .search_results {
    display: flex;
    flex-direction: row;
    font-size: 1.25rem;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #bcb7b7;
  }
  .chat_icon {
    font-size: 1.5rem;

    height: 50px;
    width: 50px;
  }
  .chat_container {
    bottom: 80px;
    right: 15px;
  }
  .chat_page {
    width: 250px;
    height: 550px;
    display: flex;
  }
  .chat_head {
    padding: 0.5rem;
  }
  .chat_head h3 {
    font-size: 1rem;
  }
  .chat_body {
    padding: 0.5rem;
  }
  .chat_body label {
    font-size: 1rem;
  }
  .chat_body input {
    width: 90%;
    height: 25px;
  }
  .chat_button {
    padding: 0;
  }

  .chat_button button {
    width: 115px;
    height: 40px;
    padding: 0.5rem;
    font-size: 0.85rem;
  }
  .chat_box textarea {
    width: 100%;
    height: 75%;
    outline: none;
  }
}

.container {
  height: 100%;
  width: 100%;
  font-family: "poppins", sans-serif;
}
.confirmation_page {
  display: flex;
  flex-direction: column;
  border: 1px solid #b5b3b3;
  margin: 2rem;
  width: 35%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}
.confirmation_head {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
}
.confirmation_head p {
  font-size: 0.75rem;
  color: #959595;
}
.head_up {
  font-size: 4rem;
  color: rgb(18, 110, 18);
}
.head_bottom p {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(32, 30, 30);
}
.confirmation_body {
  margin: 1rem;
}
.body_up h2 {
  font-size: 1.25rem;
  font-weight: 600;
}
.body_up p {
  font-size: 0.95rem;
  color: #959595;
  padding: 0.5rem 0;
}
.body_bottom {
  padding: 0.75rem 0;
}
.body_bottom h2 {
  font-size: 1.25rem;
  font-weight: 600;
}
.body_bottom p {
  font-size: 0.95rem;
  color: #959595;
  padding: 0.5rem 0;
}
.order_detail {
  margin: 1rem;
}
.order_detail h2 {
  font-size: 1.5rem;
  font-weight: 600;
}
.order_rate {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 1rem 0;
}
.order_price {
  font-size: 1.25rem;
  font-weight: 600;
}
.order_left p {
  color: #959595;
}
.order_right {
  color: #959595;
}
.total {
  font-size: 1.25rem;
}
.total_price {
  font-size: 1.25rem;
  font-weight: 600;
}
.order_button {
  margin-left: auto;
  margin-right: auto;
}
.order_button button {
  background: black;
  border: none;
  color: #fff;
  width: 350px;
  height: 40px;
  font-size: 1rem;
  margin: 1rem;
  padding: 0.25rem;
  border-radius: 5px;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .confirmation_page {
    display: flex;
    flex-direction: column;
    border: 1px solid #b5b3b3;
    margin: 1rem;
    width: 95%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
  }
  .head_up {
    font-size: 3.5rem;
    color: rgb(18, 110, 18);
  }
  .head_bottom p {
    font-size: 1.25rem;
    font-weight: 600;
    color: rgb(32, 30, 30);
  }
  .confirmation_body {
    margin: 0.5rem;
  }
  .body_up h2 {
    font-size: 1.15rem;
    font-weight: 600;
  }
  .body_up p {
    font-size: 0.95rem;
    color: #959595;
    padding: 0.25rem 0;
  }
  .body_bottom {
    padding: 0.25rem 0;
  }
  .body_bottom h2 {
    font-size: 1.15rem;
    font-weight: 600;
  }
  .body_bottom p {
    font-size: 0.95rem;
    color: #959595;
    padding: 0.25rem 0;
  }
  .order_detail {
    margin: 0.5rem;
  }
  .order_detail h2 {
    font-size: 1.35rem;
    font-weight: 700;
  }
  .order_price {
    font-size: 1.15rem;
    font-weight: 600;
  }
  .total {
    font-size: 1.15rem;
  }
  .order_button button {
    background: black;
    border: none;
    color: #fff;
    width: 325px;
    height: 40px;
    font-size: 1rem;
    margin: 0.75rem;
    padding: 0.25rem;
    border-radius: 5px;
  }
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.Login_container__29W9A {
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(250, 244, 244, 1) 0%,
    rgba(252, 116, 118, 0.42620798319327735) 47%,
    rgba(255, 239, 239, 1) 100%,
    rgba(208, 195, 195, 1) 100%
  ); */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}
.Login_left__2bIXk {
  width: 50%;
  height: 100%;

  /* border: 2px solid #fff; */

  padding: 35px;
}
.Login_left__2bIXk img {
  border-radius: 30px 0 0 30px;
  width: 100%;
  height: 100%;
}

.Login_right__1wZ0b {
  padding: 35px;
  /* background-color: #f3fff0; */
  width: 50%;
  height: 100%;
  /* border-radius: 0 25px 25px 0; */
  border-left: 0px;
  /* border: 2px solid #fff; */
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.Login_form_border__2a4Mk {
  /* border: 1px solid #de4b6e; */
  /* border-radius: 10px; */
  width: 100%;
  height: 100%;
  /* position: absolute; */
  /* top: 20%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Login_form_border__2a4Mk h1 {
  text-align: center;
  color: #18191f;
  font-size: 30px;
}
.Login_form_border__2a4Mk p {
  font-size: 20px;
  text-align: center;
  color: #4e524e;
}
.Login_register_form__21KGW {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.5rem;
  width: 100%;
}
.Login_email__3Ly9E {
  margin: 0.5rem 0;
  text-align: left;
  font-family: "Poppins", sans-serif;
  border: 1px solid #3988f7;
  font-weight: 500;
  padding: 1rem;
  font-size: 1.25rem;
  border-radius: 15px;
  width: 50%;
  outline: none;
  display: flex;
  justify-content: space-between;
}
.Login_icons__1LQqA {
  color: #3a71f2;
}
.Login_email__3Ly9E input {
  text-align: left;
  font-family: "Poppins", sans-serif;

  font-weight: 500;

  font-size: 1.25rem;
  width: 100%;
  outline: none;
  border: none;
}
.Login_password__1aLOh {
  margin: 0.5rem 0;
  text-align: left;
  font-family: "Poppins", sans-serif;
  border: 1px solid #3988f7;
  font-weight: 500;
  padding: 1rem;
  font-size: 1.25rem;
  border-radius: 15px;
  width: 50%;
  outline: none;
  display: flex;
  justify-content: space-between;
}
.Login_password__1aLOh input {
  text-align: left;
  font-family: "Poppins", sans-serif;

  font-weight: 500;

  font-size: 1.25rem;
  width: 100%;
  outline: none;
  border: none;
}
.Login_register_form__21KGW input::-webkit-input-placeholder {
  color: #3a71f2;
}
.Login_register_form__21KGW button {
  padding: 14px;
  border: none;
  margin: 0.5rem;
  width: 38%;
  border-radius: 5px;
  background: #283fbf;
  color: #fff;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  border: none;
  outline: none;
  font-weight: 500;
}
.Login_register_form__21KGW p {
  font-size: 15px;
  text-align: end;
}
.Login_reset__1buSB {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.Login_remember__2ZEG2 {
  text-align: start;
  color: #4e524e;
}
.Login_remember__2ZEG2 label {
  padding: 0.25rem;
}

.Login_forgot__2qyIO {
  text-align: start;
  color: #4e524e;
}
.Login_register__3Dd9h {
  display: flex;
}
.Login_register__3Dd9h p {
  font-size: 15px;
  color: #4e524e;
  padding: 0.25rem;
}
.Login_register__3Dd9h button {
  border: none;
  color: #1b0fbf;
  background: none;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .Login_container__29W9A {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "Poppins", sans-serif;
  }
  .Login_left__2bIXk {
    width: 0;
    height: 100%;
    padding: 0;
  }
  .Login_right__1wZ0b {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Login_form_border__2a4Mk {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Login_form_border__2a4Mk h1 {
    text-align: center;
    color: #18191f;
    font-size: 30px;
  }
  .Login_form_border__2a4Mk p {
    font-size: 20px;
    text-align: center;
    color: #4e524e;
  }
  .Login_register_form__21KGW {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.25rem;
    width: 100%;
  }
  .Login_email__3Ly9E {
    margin: 1rem 0;
    text-align: left;
    font-family: "Poppins", sans-serif;
    border: 1px solid #3988f7;
    font-weight: 500;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: 15px;
    width: 100%;
    outline: none;
    display: flex;
    justify-content: space-between;
  }
  .Login_icons__1LQqA {
    color: #3a71f2;
  }
  .Login_register_form__21KGW input {
    text-align: left;
    font-family: "Poppins", sans-serif;

    font-weight: 500;

    font-size: 1.25rem;

    outline: none;
    border: none;
  }
  .Login_password__1aLOh {
    margin: 1rem 0;
    text-align: left;
    font-family: "Poppins", sans-serif;
    border: 1px solid #3988f7;
    font-weight: 500;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: 15px;
    width: 100%;
    outline: none;
    display: flex;
    justify-content: space-between;
  }
  .Login_register_form__21KGW input::-webkit-input-placeholder {
    color: #3a71f2;
  }
  .Login_register_form__21KGW button {
    padding: 14px;
    border: none;
    margin: 1rem;
    width: 38%;
    border-radius: 5px;
    background: #283fbf;
    color: #fff;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    border: none;
    outline: none;
    font-weight: 500;
  }
  .Login_register_form__21KGW p {
    font-size: 15px;
    text-align: end;
  }
  .Login_reset__1buSB {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem;
  }
  .Login_remember__2ZEG2 {
    text-align: start;
    color: #4e524e;
  }
  .Login_remember__2ZEG2 label {
    padding: 0.25rem;
  }
  .Login_forgot__2qyIO {
    text-align: start;
    color: #4e524e;
  }
  .Login_register__3Dd9h {
    display: flex;
  }
  .Login_register__3Dd9h p {
    font-size: 15px;
    color: #4e524e;
    padding: 0.25rem;
  }
  .Login_register__3Dd9h button {
    border: none;
    color: #1b0fbf;
    background: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .Login_container__29W9A {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "Poppins", sans-serif;
  }
  .Login_left__2bIXk {
    width: 0;
    height: 100%;
    padding: 0;
  }
  .Login_right__1wZ0b {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Login_form_border__2a4Mk {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Login_form_border__2a4Mk h1 {
    text-align: center;
    color: #18191f;
    font-size: 50px;
  }
  .Login_form_border__2a4Mk p {
    font-size: 35px;
    text-align: center;
    color: #4e524e;
  }
  .Login_register_form__21KGW {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.25rem;
    width: 100%;
  }
  .Login_email__3Ly9E {
    margin: 2rem 0;
    text-align: left;
    font-family: "Poppins", sans-serif;
    border: 2px solid #3988f7;
    font-weight: 500;
    padding: 1rem;
    font-size: 2.25rem;
    border-radius: 35px;
    width: 100%;
    outline: none;
    display: flex;
    justify-content: space-between;
  }
  .Login_icons__1LQqA {
    color: #3a71f2;
  }
  .Login_register_form__21KGW input {
    text-align: left;
    font-family: "Poppins", sans-serif;

    font-weight: 500;

    font-size: 2rem;

    outline: none;
    border: none;
  }
  .Login_password__1aLOh {
    margin: 0.75rem 0;
    text-align: left;
    font-family: "Poppins", sans-serif;
    border: 2px solid #3988f7;
    font-weight: 500;
    padding: 1rem;
    font-size: 2.25rem;
    border-radius: 35px;
    width: 100%;
    outline: none;
    display: flex;
    justify-content: space-between;
  }
  .Login_register_form__21KGW input::-webkit-input-placeholder {
    color: #3a71f2;
  }
  .Login_register_form__21KGW button {
    padding: 15px;
    border: none;
    margin: 2rem;
    width: 40%;
    border-radius: 5px;
    background: #283fbf;
    color: #fff;
    font-size: 30px;
    font-family: "Poppins", sans-serif;
    border: none;
    outline: none;
    font-weight: 500;
  }
  .Login_register_form__21KGW p {
    font-size: 15px;
    text-align: end;
  }
  .Login_reset__1buSB {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem;
  }
  .Login_remember__2ZEG2 {
    text-align: start;
    color: #4e524e;
  }
  .Login_remember__2ZEG2 label {
    padding: 0.25rem;
    font-size: 1.75rem;
  }

  .Login_forgot__2qyIO p {
    text-align: start;
    color: #4e524e;
    font-size: 1.75rem;
  }
  .Login_register__3Dd9h {
    display: flex;
  }
  .Login_register__3Dd9h p {
    font-size: 35px;
    color: #4e524e;
    padding: 0.25rem;
  }
  .Login_register__3Dd9h button {
    border: none;
    color: #1b0fbf;
    background: none;
    font-size: 35px;
  }
}

.Register_container__Ot-_Q {
    /* background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(250, 244, 244, 1) 0%,
      rgba(252, 116, 118, 0.42620798319327735) 47%,
      rgba(255, 239, 239, 1) 100%,
      rgba(208, 195, 195, 1) 100%
    ); */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "Poppins", sans-serif;
  }
  .Register_left__1g1aE {
    width: 50%;
    height: 100%;
  
    /* border: 2px solid #fff; */
   
    padding: 35px;
   
  }
  .Register_left__1g1aE img {
    border-radius: 30px 0 0 30px;
    width: 100%;
    height: 100%;
  }
  
  .Register_right__eCpzY {
      padding: 35px;
    /* background-color: #f3fff0; */
    width: 50%;
    height: 100%;
    /* border-radius: 0 25px 25px 0; */
    border-left: 0px;
    /* border: 2px solid #fff; */
    /* position: relative; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .Register_form_border__fSW6l {
    /* border: 1px solid #de4b6e; */
    /* border-radius: 10px; */
    width: 100%;
    height: 100%;
    /* position: absolute; */
    /* top: 20%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Register_form_border__fSW6l h1 {
    text-align: center;
    color: #18191f;
    font-size: 30px;
  }
  .Register_form_border__fSW6l p {
    font-size: 20px;
    text-align: center;
    color: #4e524e;
  }
  .Register_register_form__1Xp-v {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1.5rem;
    width: 100%;
  }
  .Register_email__-jBGf {
    margin: 0.5rem 0;
    text-align: left;
    font-family: "Poppins", sans-serif;
    border: 1px solid #3988f7;
    font-weight: 500;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: 15px;
    width: 50%;
    outline: none;
    display: flex;
    justify-content: space-between;
  }
  .Register_icons__swOEf{
      color: #3a71f2;
  }
  .Register_register_form__1Xp-v input {
    text-align: left;
    font-family: "Poppins", sans-serif;
  
    font-weight: 500;
  
    font-size: 1.25rem;
  
    outline: none;
    border: none;
  
   
  }
  .Register_password__KHJu6 {
    margin: 0.5rem 0;
    text-align: left;
    font-family: "Poppins", sans-serif;
    border: 1px solid #3988f7;
    font-weight: 500;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: 15px;
    width: 50%;
    outline: none;
    display: flex;
    justify-content: space-between;
  }
  .Register_register_form__1Xp-v input::-webkit-input-placeholder {
    color: #3a71f2;
  }
  .Register_register_form__1Xp-v button {
    padding: 14px;
    border: none;
    margin: 0.5rem;
    width: 38%;
    border-radius: 5px;
    background: #283fbf;
    color: #fff;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    border: none;
    outline: none;
    font-weight: 500;
  }
  .Register_register_form__1Xp-v p{
      font-size: 15px;
      text-align: end;
  }
  .Register_reset__2cAO1{
  display: flex;
  justify-content: space-between;
  width: 50%;
  }
  .Register_remember__dj4wq{
  
    text-align: start;
    color: #4e524e;
  }
  .Register_remember__dj4wq label{
    padding: 0.25rem;
  }
  .Register_forgot__15PfD{
   
    text-align: start;
    color: #4e524e;
  }
  .Register_register__2GRRi{
      display: flex;
  }
  .Register_register__2GRRi p{
      font-size: 15px;
      color: #4e524e;
      padding: 0.25rem;
  }
  .Register_register__2GRRi button{
      border: none;
      color: #1b0fbf;
      background: none;
  }
  @media only screen and (max-width: 600px) {
    
  }
.NavBar_container__1Axxu {
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.NavBar_container__1Axxu::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.NavBar_navbar__1Smsj {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}
.NavBar_left__2OYXS {
  padding: 0.5rem;

  width: 25%;
}
.NavBar_link__2m8yw {
  color: #1a1b24;
  font-size: 1.25rem;
  text-decoration: none;
}
.NavBar_center__1WQDU {
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.NavBar_search__2rhLX {
  display: flex;
  background-color: #fff;
  width: 40%;
  justify-content: space-between;
  border: 2px solid #02043c;
  padding: 0.75rem;
  border-radius: 35px;
  align-items: center;
}

.NavBar_search_text__12Wlo input {
  font-size: 1.25rem;

  outline: none;
  border: none;
  width: 100%;
  color: #04099b;
}
.NavBar_search_text__12Wlo input::-webkit-input-placeholder {
  color: #15162b;
}
.NavBar_icons__12G9C {
  color: #02142d;
  font-size: 1.25rem;
}
.NavBar_right__3-ZOB {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.NavBar_profile__2H7aC {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 0.5rem;
  font-family: "Poppins";
  width: 50%;
}
.NavBar_profile__2H7aC p {
  font-size: 1.25rem;
  margin: 0 0.75rem;
  color: #074276;
}
.NavBar_profile__2H7aC svg {
  font-size: 1.5rem;
  color: #1a1b24;
  cursor: pointer;
}
.NavBar_cartButton__3yxOn {
  font-size: 1.5rem;
  color: #1a1b24;
  cursor: pointer;
}
.NavBar_cartButton__3yxOn span {
  position: absolute;
  top: 10px;
  font-size: 1.25rem;
  font-weight: 600;
  color: #99141d;
}
.NavBar_drop_menu__3m0Iv {
  position: absolute;
  top: 5rem;
  right: 5px;
  /* margin: 1rem; */
  background-color: #f3f4f3;
  width: 12rem;
  height: 12rem;
  z-index: 2;
  border: 2px solid #2c302d;
  border-radius: 20px;
}
.NavBar_drop_item__2N46k {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* margin: 1.5rem; */
  padding: 1rem;
  justify-content: space-between;
}
.NavBar_mobile_search__17TXw {
  display: none;
}
.NavBar_mobile_search__17TXw input {
  width: 20%;
}
.NavBar_myprofile__offrS {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 1.1rem;
}
.NavBar_my_icon__32j6l {
  width: 30%;
}
.NavBar_my_prof__2dHmO {
  width: 70%;
}
.NavBar_my_prof__2dHmO p {
  color: black;
}
.NavBar_myorder__38pTl {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 1.1rem;
}
.NavBar_order_icon__3A88E {
  width: 30%;
}
.NavBar_order__6QlYD {
  width: 70%;
}
.NavBar_order__6QlYD p {
  color: black;
}
.NavBar_cart__3fLO5 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 1.1rem;
}
.NavBar_cart_icon__2aHyT {
  width: 30%;
}
.NavBar_cart_text__3HraF {
  width: 70%;
}
.NavBar_cart_text__3HraF p {
  color: black;
}

.NavBar_logout__1Lkgf {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 1.1rem;
}
.NavBar_log_icon__3SfG5 {
  width: 30%;
}
.NavBar_log__3XKlE {
  width: 70%;
  cursor: pointer;
}

.NavBar_search_mobile__1noqT {
  display: none;
}
.NavBar_search_popup__1V7gy {
  width: 20%;
  height: auto;
  background-color: #ffffff;
  position: absolute;
  right: 40%;
  border-radius: 5px;
}
.NavBar_popup_center__rq-Bh {
  display: flex;
  align-items: center;
  justify-content: center;
}
.NavBar_popup_main__YS7Qi {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.75rem;
}
.NavBar_popup_head__3OozU {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  z-index: 5;
  flex-direction: column;
}
.NavBar_popup_text__1ySNJ {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
}
.NavBar_popup_text__1ySNJ p {
  font-size: 1.15rem;
  color: rgb(44 14 14 / 81%);
}
.NavBar_popup_close__32qdQ {
  font-size: 1.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.NavBar_chat_icon__EjxPR {
  position: fixed;
  bottom: 50px;
  right: 10px;
  font-size: 2.5rem;
  color: #f5f5ff;
  height: 65px;
  width: 65px;
  background: #0c0446;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NavBar_chat_container__3EsV0 {
  position: fixed;
  bottom: 100px;
  right: 100px;
  z-index: 200;
}
.NavBar_chat_page__9snrC {
  /* border: 2px solid #272020; */
  width: 400px;
  height: 650px;
  display: flex;
  flex-direction: column;
  /* padding: 1rem; */
  align-items: flex-start;
  justify-content: flex-start;
  /* background: #4595d7;
     color: white; */
  /* border-radius: 5px; */
  overflow: hidden;
}
.NavBar_chat_head__3C-H- {
  background: #302aa9;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 2rem 0 0 0rem;
  color: #fff;
}
.NavBar_chat_head__3C-H- h3 {
  font-size: 1.5rem;
  font-weight: 800;
}
.NavBar_chat_body__11Bg3 {
  color: #000;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  border-radius: 0 0 2rem 0;
  overflow: hidden;
  padding: 1rem;
  border: 3px solid #b8c9da;
  border-top: #302aa9;
}
.NavBar_chat_body__11Bg3 label {
  font-size: 1.25rem;
}
.NavBar_chat_body__11Bg3 input {
  border: 1px solid #a7a7cd;
  /* background: #8d9dfe; */
  width: 85%;
  height: 30px;
  outline: none;
  border-radius: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  /* /* border: none; */
}
.NavBar_chat_name__1AIoa {
  display: flex;
  flex-direction: column;
  height: 40%;
  justify-content: space-between;
}
.NavBar_chat_number__2lvI6 {
  display: flex;
  flex-direction: column;
  height: 40%;
  justify-content: space-between;
}
.NavBar_chat_button__3esG4 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0;
}
.NavBar_chat_button__3esG4 button {
  width: 150px;
  height: 45px;
  border: none;
  border-radius: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  background: #393597;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}
.NavBar_chat_box__MCkHv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.NavBar_chat_box__MCkHv textarea {
  width: 100%;
  height: 80%;
  outline: none;
  border: 1px solid #a7a7cd;
}
/* mobile screen */

@media only screen and (max-width: 767px) {
  .NavBar_container__1Axxu {
    width: 100%;
    height: 10vh;
    font-family: "Poppins", sans-serif;
  }
  .NavBar_navbar__1Smsj {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem;
  }
  .NavBar_left__2OYXS {
    color: #1a1b24;
    font-size: 1rem;
    width: 65%;
    padding: 0.5rem;
  }
  .NavBar_center__1WQDU {
    width: 0%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .NavBar_search__2rhLX {
    display: none;
  }

  .NavBar_right__3-ZOB {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .NavBar_profile__2H7aC {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    width: 17%;
    font-size: 1.15rem;
    font-family: "Poppins";
    color: #1a1b24;
  }
  .NavBar_profile_text__132vU {
    display: none;
  }
  .NavBar_prof_icon__1eB7m {
    font-size: 1rem;
  }

  .NavBar_drop_menu__3m0Iv {
    position: absolute;
    top: 3rem;
    right: 0;
    margin: 1rem;
    background-color: #e5ffd6;
    width: 50%;
    z-index: 2;
    border: 2px solid #c5d2b8;
    border-radius: 20px;
  }
  .NavBar_drop_item__2N46k {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0rem;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
  }
  .NavBar_mobile_search__17TXw {
    display: flex;
    padding: 0.5rem 0;
    align-items: center;
    justify-content: space-around;
    width: 75%;
    font-size: 1.25rem;
  }
  .NavBar_mobsearch_icon__1a2cN {
    font-size: 1.5rem;
  }

  .NavBar_myprofile__offrS {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0.5rem 0;
  }
  .NavBar_my_icon__32j6l {
    width: 30%;
  }
  .NavBar_my_prof__2dHmO {
    width: 70%;
  }
  .NavBar_my_prof__2dHmO p {
    color: black;
  }
  .NavBar_myorder__38pTl {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0.5rem 0;
  }
  .NavBar_order_icon__3A88E {
    width: 30%;
  }
  .NavBar_order__6QlYD {
    width: 70%;
  }
  .NavBar_order__6QlYD p {
    color: black;
  }
  .NavBar_cart__3fLO5 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0.5rem 0;
  }
  .NavBar_cart_icon__2aHyT {
    width: 30%;
  }
  .NavBar_cart_text__3HraF {
    width: 70%;
  }
  .NavBar_cart_text__3HraF p {
    color: black;
  }

  .NavBar_logout__1Lkgf {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0.5rem 0;
  }
  .NavBar_log_icon__3SfG5 {
    width: 30%;
  }
  .NavBar_log__3XKlE {
    width: 70%;
  }

  .NavBar_search_mobile__1noqT {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    position: fixed;
    z-index: 200;
    top: 0;
  }
  .NavBar_mobile_fullsearch__2BHc7 {
    display: flex;
    background-color: #242222;
    width: 100%;
    justify-content: space-between;
    border: 2px solid #ffffff;
    padding: 0.5rem;
    border-radius: 10px;
    align-items: center;
    z-index: 8;
    position: absolute;
    top: 10px;
  }
  .NavBar_search_left__vaWU4 input {
    width: 330px;
    height: 30px;
    border: none;
    outline: none;
    font-size: 1.25rem;
    color: #fff;
    background-color: #242222;
  }
  .NavBar_search_right__3dA5p {
    font-size: 1.5rem;
    color: #fff;
  }
  .NavBar_mobsearch_result__4o_dc {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    align-items: flex-start;
    justify-content: space-around;
    padding: 1rem;
  }
  .NavBar_search_results__1fOfM {
    display: flex;
    flex-direction: row;
    font-size: 1.25rem;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #bcb7b7;
  }
  .NavBar_chat_icon__EjxPR {
    font-size: 1.5rem;

    height: 50px;
    width: 50px;
  }
  .NavBar_chat_container__3EsV0 {
    bottom: 80px;
    right: 15px;
  }
  .NavBar_chat_page__9snrC {
    width: 250px;
    height: 550px;
    display: flex;
  }
  .NavBar_chat_head__3C-H- {
    padding: 0.5rem;
  }
  .NavBar_chat_head__3C-H- h3 {
    font-size: 1rem;
  }
  .NavBar_chat_body__11Bg3 {
    padding: 0.5rem;
  }
  .NavBar_chat_body__11Bg3 label {
    font-size: 1rem;
  }
  .NavBar_chat_body__11Bg3 input {
    width: 90%;
    height: 25px;
  }
  .NavBar_chat_button__3esG4 {
    padding: 0;
  }

  .NavBar_chat_button__3esG4 button {
    width: 115px;
    height: 40px;
    padding: 0.5rem;
    font-size: 0.85rem;
  }
  .NavBar_chat_box__MCkHv textarea {
    width: 100%;
    height: 75%;
    outline: none;
  }
}

.Home_container__1tyoc {
  width: 100%;
  height: 90vh;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
}
.Home_container__1tyoc::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.Home_home__2yMNW {
  padding: 1rem;
}
.Home_add__2jnJa {
  width: 100%;
  height: 500px;
  background: #ffe2b5;
  border-radius: 3rem;
  display: flex;
  flex-direction: row;
}
.Home_ad_left__2uHr6 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  align-items: flex-start;
  padding: 5.5rem;
}
.Home_left_write__2S14- {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  /* height: 100%; */
}
.Home_left_write__2S14- h1 {
  font-weight: 400;
  font-family: "poppins";
  font-size: 3.5rem;
}
.Home_left_write__2S14- h2 {
  font-size: 3.5rem;
  font-weight: 800;
  font-family: "poppins";
}
.Home_left_write__2S14- p {
  font-size: 1.5rem;
  font-family: "poppins";
  color: #161a44;
}
.Home_left_bottom__1c2JL {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  width: 180px;
  height: 65px;
  background: black;
  color: gold;
  padding: 0.5rem;
  font-size: 1.5rem;
}

.Home_left_bottom__1c2JL button {
  border: none;
  background: black;
  color: gold;
  padding: 0.5rem;
  font-size: 1.5rem;
}
.Home_left_bottom__1c2JL:hover {
  background-color: rgb(51, 46, 46);
}
.Home_left_bottom__1c2JL button:hover {
  background-color: rgb(51, 46, 46);
}
.Home_ad_right__1-a0f {
  width: 100%;
  height: 100%;
  position: relative;
}
.Home_ad_right__1-a0f img {
  width: 70%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  right: 0;
}
.Home_brand__1iE1R {
  margin: 1.5rem 0;
}
.Home_brand__1iE1R h2 {
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 500;

  color: #0c0101;
  -webkit-clip-path: polygon(
    0 0,
    0 0,
    100% 0,
    100% 0,
    100% calc(100% - 15px),
    calc(100% - 15px) 100%,
    15px 100%,
    0 100%
  );
  clip-path: polygon(
    0 0,
    0 0,
    100% 0,
    100% 0,
    100% calc(100% - 15px),
    calc(100% - 15px) 100%,
    15px 100%,
    0 100%
  );
  height: 40px;
  font-size: 1.25rem;
  transition: 0.2s 0.1s;
  background-image: linear-gradient(90deg, #d6c12b, #e6bece);
  border: 0 solid;
  padding: 0.25rem 0.75rem;
  width: 250px;
}
.Home_brand__1iE1R h2:hover {
  cursor: pointer;
  transition: all 0.3s ease-in;
  padding-right: 30px;
  padding-left: 30px;
}
.Home_brand_cards__3wNnn {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  margin: 1rem;
  padding: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}
.Home_card_page__145ax {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 1rem;
  border: 2px solid #e3e3e3;
  justify-content: space-around;
  cursor: pointer;
}
.Home_card_page__145ax h1 {
  padding: 0.5rem 0;
  font-size: 1.25rem;
  font-weight: 500;
}
.Home_card__3lXfz {
  width: 25%;
  transition: all 0.5s;
  color: black;
}

.Home_card__3lXfz:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.Home_card__3lXfz:active {
  -webkit-transform: scale(0.95) rotateZ(1.7deg);
          transform: scale(0.95) rotateZ(1.7deg);
}
.Home_card__3lXfz img {
  width: 100%;
  height: 100%;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .Home_add__2jnJa {
    width: 100%;
    height: 250px;
    background: #ffe2b5;
    border-radius: 3rem;
    display: flex;
    flex-direction: row;
  }
  .Home_ad_left__2uHr6 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    align-items: flex-start;
    padding: 1.5rem;
  }
  .Home_left_write__2S14- {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    /* height: 100%; */
  }
  .Home_left_write__2S14- h1 {
    font-weight: 400;
    font-family: "poppins";
    font-size: 1.25rem;
  }
  .Home_left_write__2S14- h2 {
    font-size: 1.5rem;
    font-weight: 800;
    font-family: "poppins";
  }
  .Home_left_write__2S14- p {
    font-size: 1.25rem;
    font-family: "poppins";
    color: #161a44;
  }
  .Home_left_bottom__1c2JL {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    width: 150px;
    height: 50px;
    background: black;
    color: gold;
    padding: 0.25rem;
    font-size: 1.25rem;
  }
  .Home_left_bottom__1c2JL button {
    font-size: 1rem;
  }
  .Home_ad_right__1-a0f {
    width: 0;
    height: 0;
    position: static;
  }
  .Home_ad_right__1-a0f img {
    width: 70%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    right: 0;
    display: none;
  }
  .Home_brand__1iE1R h2 {
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
    width: 180px;
  }
  .Home_brand_cards__3wNnn {
    margin: 1rem 1rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .Home_card_page__145ax {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 15px;
    margin: 15px;
    border-radius: 1rem;
    border: 2px solid #e3e3e3;
  }
  .Home_card_page__145ax h1 {
    padding: 0.25rem;
    font-size: 1rem;
    font-weight: 500;
  }
  .Home_card__3lXfz {
    width: 85%;
    height: 100%;
    cursor: pointer;
    transition: all 0.5s;
    color: black;
  }
}

.Loader_Container__2NzBw{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
}
.ProductList_container__1ggO7 {
  width: 100%;
  height: 90vh;
  background: #f2f2f2;
  font-family: "poppins", sans-serif;
}
.ProductList_container__1ggO7::-webkit-scrollbar {
  display: none;
}
.ProductList_product_page___MW5o {
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
}
.ProductList_first_product__1BgM2 {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}
.ProductList_head__3dBAB {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 25%;
  padding: 1rem 0;
}
.ProductList_head__3dBAB h1 {
  font-family: "poppins";
  font-size: 2.5rem;
  font-weight: 300;
  color: #3d1c55;
  border-bottom: 2px solid #1a0d53;
  width: 100%;
}
.ProductList_productSection__3RsJZ {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}
.ProductList_product_firstrow__2YVOo {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  margin: 1rem;
  padding: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.ProductList_product_card__3tqMC {
  /* width: 20rem; */
  border: 2px solid #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
}
.ProductList_product_card__3tqMC:hover {
  margin-top: 3px;
  box-shadow: 15px 15px 30px #949292, -15px -15px 30px #ffffff;
}
.ProductList_cardHead__36UFv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.ProductList_product_image__2ke0P {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProductList_product_image__2ke0P img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ProductList_countSelection__3kUtl {
  display: flex;
  align-items: center;
  width: 55%;
  justify-content: space-evenly;
}
.ProductList_countSelection__3kUtl button {
  font-size: 1.5rem;
  padding: 0 0.5rem;
  border: 2px solid #4779a5;
  background: none;
  color: #4d6666;
  border-radius: 5px;
  cursor: pointer;

}
.ProductList_countSelection__3kUtl input {
  width: 50px;
  padding: 0.25rem;
  border: 2px solid #4779a5;
  border-radius: 10px;
  color: #4d6666;
  outline: none;
  text-align: center;
  font-size: 1.25rem;

}
.ProductList_card_body__3P8HV {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
}
.ProductList_product_description__1ihbL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
}
.ProductList_product_name__Gy98O {
  cursor: pointer;
}
.ProductList_product_name__Gy98O p {
  font-family: "poppins";
  font-size: 1.25rem;
  color: #542176;
  font-weight: 500;
}
.ProductList_product_rate__3kOY1 p {
  font-family: "poppins";
  font-size: 1.25rem;
  color: #9d0404;
}
.ProductList_cart__3ZibF {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
}
.ProductList_cart__3ZibF input {
  width: 50%;
  margin: 0;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  cursor: pointer;

}
.ProductList_buttonsDiv__1lhcK {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProductList_AddItems__CIXw- {
  background-color: transparent;
  border: none;
  outline: none;
  font-family: 500;
  font-size: 1.5rem;
  color: #000;
  font-family: Poppins;
  text-decoration: underline;
}
.ProductList_second_product__1oWyH {
  margin: 0 2.25rem;
}
.ProductList_product_secRow__3YG7l {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ProductList_product_secondCard__1OfWx {
  width: 400px;
  height: 400px;
  /* border: 1px solid #f1edf4; */
  margin: 1.5rem 0;
  padding: 1.25rem;
  background-color: #fff;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: all 0.2s ease-in-out;
}
.ProductList_product_secondCard__1OfWx:hover {
  margin-top: 3px;
  box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
}
.ProductList_product_secondImage__2Pd3k {
  width: 80%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.ProductList_product_secondImage__2Pd3k img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ProductList_cartButton__2mOi- {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}
.ProductList_cartButton__2mOi- button {
  width: 90%;
  padding: 0.5rem ;
  border: none;
  font-size: 1.25rem;
  background: #d7d8e0;
  color: #66319c;
  border-radius: 5px;
  cursor: pointer;
}
/* mobile */

@media only screen and (max-width: 767px) {
  .ProductList_first_product__1BgM2 {
    margin: 0.75rem 1rem;
  }
  .ProductList_head__3dBAB {
    border-bottom: 2px solid #1a0d53;
    width: 100%;
  }
  .ProductList_head__3dBAB h1 {
    font-family: "poppins";
    font-size: 1.75rem;
    font-weight: 500;
    color: #33273b;
  }
  .ProductList_product_firstrow__2YVOo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .ProductList_product_card__3tqMC {
    width: 48%;
    height: 220px;
    /* border: 1px solid #f1edf4; */
    margin: 1rem 0;
    padding: 1rem;
    background-color: #fff;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: all 0.2s ease-in-out;
  }

  .ProductList_product_name__Gy98O p {
    font-family: "poppins";
    font-size: 1rem;
    color: #24172d;
    font-weight: 600;
  }
  .ProductList_product_rate__3kOY1 p {
    font-family: "poppins";
    font-size: 1rem;
    color: #24172d;
  }
  .ProductList_cart__3ZibF {
    font-size: 1.25rem;
  }
  .ProductList_product_image__2ke0P {
    width: 70%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  /* .buttonsDiv{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .AddItems{
    background-color: transparent;
    border:none;
    outline: none;
    font-family: 500;
    font-size: 1.5rem;
    color: #000;
    font-family: Poppins;
    text-decoration: underline;
  } */
  .ProductList_second_product__1oWyH {
    margin: 0 1rem;
  }

  .ProductList_product_secRow__3YG7l {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .ProductList_product_secondCard__1OfWx {
    width: 48%;
    height: auto;
    /* border: 1px solid #f1edf4; */
    margin: 1.5rem 0;
    padding: 1.25rem;
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: all 0.2s ease-in-out;
  }

  .ProductList_product_secondImage__2Pd3k {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.CartList_container__2wv7l {
  width: 100%;
  height: 100%;
  font-family: "poppins", sans-serif;
}
.CartList_cart_page__94fIo {
  display: flex;
  flex-direction: row;
  margin: 2rem;
}
.CartList_cart_list__1dn77 {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.CartList_head__2ERcf {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.CartList_head_left__x8qVB {
  width: 50%;
}
.CartList_head_right__8EtU9 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.CartList_remove_icon__3-vyP {
  font-size: 1.25rem;
}
.CartList_remove__2Cbii button {
  border: none;
  background: none;
  font-size: 1rem;
  padding: 0 0.5rem;
}
.CartList_description_head__aHV_W {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  border-bottom: 1px solid #bbbbbb;
}
.CartList_des_left__3-PG4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 10%;
  justify-content: space-between;
}
.CartList_left_check__3fqjD input {
  width: 20px;
  height: 40px;
}
.CartList_left_product__joofs {
  font-size: 1rem;
  font-weight: 400;
  color: #707177;
}
.CartList_des_right__WZZDp {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 45%;
  justify-content: space-between;
}
.CartList_right_quantity__NUJw0 {
  font-size: 1rem;
  font-weight: 400;
  color: #707177;
}
.CartList_right_price__2yyTc {
  font-size: 1rem;
  font-weight: 400;
  color: #707177;
}
.CartList_product_one__26dRz {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1rem;
  border-bottom: 1px solid #bbbbbb;
  padding: 0 0 0.75rem;
}
.CartList_product_left__1OqiM {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  align-items: center;
}
.CartList_product_check__2uu12 {
  width: 20%;
}
.CartList_product_check__2uu12 input {
  height: 1.25rem;
  width: 1.25rem;
}
.CartList_product_card__1E7Tc {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: flex-start;
}
.CartList_card_left__1Mm_Q {
  width: 30%;
  height: 50%;
  /* background-color: #d0d1db; */
  border-radius: 15px;
}
.CartList_card_left__1Mm_Q img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.CartList_card_right___A4Ok {
  padding: 0 1.25rem;
}
.CartList_card_right___A4Ok h1 {
  font-size: 1.25rem;
  font-weight: 400;
}
.CartList_card_right___A4Ok p {
  color: #707177;
}
.CartList_product_center__3Kxth {
  width: 37%;
}
.CartList_quantity__3Kiwm {
  display: flex;
  border: 1px solid #b7b8ba;
  border-radius: 5px;
  padding: 0.25rem 1.5rem;
  width: 115px;
  align-items: center;
  justify-content: space-between;
}
.CartList_quantity__3Kiwm button {
  border: none;
  background: none;
  font-size: 1.5rem;
}
.CartList_quantity__3Kiwm input {
  border: none;
  background: transparent;
  width: 35px;
  text-align: center;
  outline: none;
}
.CartList_quantity_delete__3gKIC {
  display: flex;
  flex-direction: row;
  margin: 0.75rem;
}
.CartList_delete_icon__29_8w {
  cursor: pointer;
}
.CartList_delete__2zdz8 {
  font-size: 0.95rem;
  padding: 0 0.5rem;
}
.CartList_product_right__194w- {
  width: 10%;
  font-size: 1.25rem;
  font-weight: 600;
}
.CartList_producttwo_left__25dIv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  align-items: center;
}
.CartList_confirmation__1ZCW_ {
  width: 35%;
  margin: 0 2.5rem;
  height: 100%;
}
.CartList_confirmation_dialogue__28akz {
  width: 100%;

  border: 1px solid #bbbbbb;
  border-radius: 10px;
  padding: 1.25rem;
}
.CartList_dialogue_head__1DRq5 {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-bottom: 1px solid #bbbbbb;
}
.CartList_subtottal__1Di2F {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.CartList_subtottal__1Di2F p {
  color: #707177;
  font-size: 1.2rem;
}

.CartList_discount__3SIP4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.CartList_discount__3SIP4 p {
  color: #707177;
  font-size: 1.2rem;
}
.CartList_dialogue_body__3FbTF {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.CartList_grandtotal__3rrn5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.CartList_grandtotal__3rrn5 p {
  font-size: 1.5rem;
}
.CartList_checkout__2NE2f {
  margin-left: auto;
  margin-right: auto;
}

.CartList_checkout__2NE2f button {
  border: none;
  background: black;
  color: #fff;
  width: 300px;
  height: 40px;
  font-size: 1.25rem;
  border-radius: 5px;
  cursor: pointer;
}
.CartList_add_item__3W9xk {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
.CartList_add_item__3W9xk button {
  border: none;
  background: #020245;
  color: #fff;
  width: 120px;
  height: 35px;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .CartList_cart_page__94fIo {
    display: flex;
    flex-direction: column;
    margin: 1rem;
  }
  .CartList_cart_list__1dn77 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .CartList_head_left__x8qVB {
    width: 50%;
  }
  .CartList_head_left__x8qVB h1 {
    font-size: 1.5rem;
  }
  .CartList_head_right__8EtU9 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .CartList_remove_icon__3-vyP {
    font-size: 1rem;
  }
  .CartList_remove__2Cbii button {
    border: none;
    background: none;
    font-size: 1rem;
    padding: 0 0.25rem;
  }
  .CartList_des_left__3-PG4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 28%;
    justify-content: space-between;
  }
  .CartList_left_check__3fqjD input {
    width: 13px;
    height: 13px;
  }
  .CartList_left_product__joofs {
    font-size: 0.95rem;
    font-weight: 400;
    color: #707177;
  }
  .CartList_des_right__WZZDp {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    justify-content: space-between;
  }
  .CartList_right_quantity__NUJw0 {
    font-size: 0.95rem;
    font-weight: 400;
    color: #707177;
  }
  .CartList_right_price__2yyTc {
    font-size: 0.95rem;
    font-weight: 400;
    color: #707177;
  }
  .CartList_product_one__26dRz {
    margin: 1rem 0;
  }
  .CartList_product_left__1OqiM {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 55%;
    align-items: center;
  }
  .CartList_product_check__2uu12 {
    width: 15%;
  }
  .CartList_product_check__2uu12 input {
    height: 17px;
    width: 17px;
  }
  .CartList_product_card__1E7Tc {
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: flex-start;
  }
  .CartList_card_left__1Mm_Q {
    width: 50%;
    height: 50%;
    /* background-color: #d0d1db; */
    border-radius: 15px;
  }
  .CartList_card_left__1Mm_Q img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .CartList_card_right___A4Ok {
    padding: 0 1rem;
  }
  .CartList_card_right___A4Ok h1 {
    font-size: 1rem;
    font-weight: 400;
  }
  .CartList_card_right___A4Ok p {
    color: #707177;
    font-size: 0.95rem;
  }

  .CartList_product_center__3Kxth {
    width: 30%;
  }
  .CartList_quantity__3Kiwm {
    display: flex;
    border: 1px solid #b7b8ba;
    border-radius: 5px;
    padding: 0.25rem 0.75rem;
    width: 80px;
    align-items: center;
    justify-content: space-between;
  }
  .CartList_quantity__3Kiwm button {
    border: none;
    background: none;
    font-size: 1.25rem;
  }
  .CartList_quantity__3Kiwm input {
    border: none;
    background: transparent;
    width: 35px;
    text-align: center;
    outline: none;
  }
  .CartList_quantity_delete__3gKIC {
    display: flex;
    flex-direction: row;
    margin: 0.5rem;
  }
  .CartList_delete__2zdz8 {
    font-size: 0.75rem;
    padding: 0 0.5rem;
  }
  .CartList_product_right__194w- {
    width: 15%;
    font-size: 1rem;
    font-weight: 600;
  }
  .CartList_confirmation__1ZCW_ {
    width: 100%;
    margin: 0rem;
  }

  .CartList_subtottal__1Di2F p {
    color: #707177;
    font-size: 1rem;
  }

  .CartList_discount__3SIP4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .CartList_discount__3SIP4 p {
    color: #707177;
    font-size: 1rem;
  }
  .CartList_dialogue_body__3FbTF {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .CartList_grandtotal__3rrn5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
  }
  .CartList_grandtotal__3rrn5 p {
    font-size: 1.25rem;
  }
  .CartList_checkout__2NE2f {
    margin-left: auto;
    margin-right: auto;
  }

  .CartList_checkout__2NE2f button {
    border: none;
    background: black;
    color: #fff;
    width: 300px;
    height: 40px;
    font-size: 1.25rem;
    border-radius: 5px;
    cursor: pointer;
  }
  .CartList_add_item__3W9xk {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin: 0.5rem 0;
  }
  .CartList_add_item__3W9xk button {
    border: none;
    background: #020245;
    color: #fff;
    width: 100px;
    height: 35px;
    padding: 0.5rem;
    border-radius: 4px;
    font-size: 0.85rem;
  }
}

.MyProfile_container__tVfy7 {
  width: 100%;
  height: 100%;
  font-family: "poppins", sans-serif;
}
.MyProfile_profile_page__3WzzJ {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 3rem 4rem;
}
.MyProfile_prof_head__1RADm {
  border-bottom: 1px solid #362b2b;
  width: 30%;
}
.MyProfile_prof_head__1RADm h1 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #3c1111;
}
.MyProfile_profile_Name__1msZZ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  margin: 0.5rem 0;
}
.MyProfile_first_name__27w-G {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.MyProfile_first_name__27w-G label {
  font-size: 1.25rem;
  font-size: 1.25rem;
  padding: 1rem 0;
}
.MyProfile_first_name__27w-G input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
}
.MyProfile_second_name__14VkB {
  display: flex;
  flex-direction: column;
}
.MyProfile_second_name__14VkB label {
  font-size: 1.25rem;
  font-size: 1.25rem;
  padding: 1rem 0;
}
.MyProfile_second_name__14VkB input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
}
.MyProfile_information__23iee {
  display: flex;
  flex-direction: column;
}
.MyProfile_information__23iee label {
  font-size: 1.25rem;
  font-size: 1.25rem;
  padding: 1rem 0;
}
.MyProfile_information__23iee input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
  width: 550px;
}
.MyProfile_location__2wbxd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30%;
}
.MyProfile_city__WGe4q {
  display: flex;
  flex-direction: column;
}
.MyProfile_city__WGe4q label {
  font-size: 1.25rem;
  padding: 1rem 0;
}
.MyProfile_city__WGe4q input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
}
.MyProfile_state__2y07l {
  display: flex;
  flex-direction: column;
}
.MyProfile_state__2y07l label {
  font-size: 1.25rem;
  padding: 1rem 0;
}
.MyProfile_state__2y07l input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
}
.MyProfile_second_location__1zkDZ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30%;
}
.MyProfile_pincode__3DyOH {
  display: flex;
  flex-direction: column;
}
.MyProfile_pincode__3DyOH label {
  font-size: 1.25rem;
  padding: 1rem 0;
}
.MyProfile_pincode__3DyOH input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
}
.MyProfile_country__1Puy9 {
  display: flex;
  flex-direction: column;
}
.MyProfile_country__1Puy9 label {
  font-size: 1.25rem;
  padding: 1rem 0;
}
.MyProfile_country__1Puy9 input {
  outline: none;
  padding: 0.5rem;
  border: 1px solid #aaa2a2;
  border-radius: 5px;
}

.MyProfile_submitt__2sXAv button {
  border: none;
  background: black;
  color: #fff;
  width: 100px;
  height: 50px;
  border-radius: 5px;
  padding: 0.25rem;
  font-size: 1rem;
  margin: 1rem 0;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .MyProfile_profile_page__3WzzJ {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0.25rem 1.5rem;
  }
  .MyProfile_prof_head__1RADm {
    border-bottom: 1px solid #362b2b;
    width: 100%;
  }
  .MyProfile_prof_head__1RADm h1 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #3c1111;
  }
  .MyProfile_profile_Name__1msZZ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.75rem 0;
  }
  .MyProfile_first_name__27w-G {
    width: 49%;
  }
  .MyProfile_first_name__27w-G label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .MyProfile_second_name__14VkB {
    width: 49%;
  }
  .MyProfile_second_name__14VkB label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .MyProfile_information__23iee {
    width: 100%;
  }
  .MyProfile_information__23iee label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .MyProfile_information__23iee input {
    outline: none;
    padding: 0.5rem;
    border: 1px solid #aaa2a2;
    border-radius: 5px;
    width: 100%;
  }
  .MyProfile_location__2wbxd {
    width: 100%;
  }
  .MyProfile_city__WGe4q {
    width: 49%;
  }
  .MyProfile_city__WGe4q label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .MyProfile_state__2y07l {
    width: 49%;
  }
  .MyProfile_state__2y07l label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .MyProfile_second_location__1zkDZ {
    width: 100%;
  }
  .MyProfile_pincode__3DyOH {
    width: 49%;
  }
  .MyProfile_pincode__3DyOH label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .MyProfile_country__1Puy9 {
    width: 49%;
  }
  .MyProfile_country__1Puy9 label {
    font-size: 1rem;
    padding: 0.25rem 0;
  }
  .MyProfile_submitt__2sXAv {
    margin-left: auto;
    margin-right: auto;
  }
  .MyProfile_submitt__2sXAv button {
    border: none;
    background: black;
    color: #fff;
    width: 250px;
    height: 45px;
    border-radius: 5px;
    padding: 0.25rem;
    font-size: 1.25rem;
    margin: 1rem 0;
  }
}

.CheckOut_container__2eV2U {
  height: 100%;
  width: 100%;
  font-family: "poppins", sans-serif;
}
.CheckOut_checkout_page__2dftX {
  display: flex;
  flex-direction: row;
  margin: 2rem;
}
.CheckOut_checkout__3zVO3 {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.CheckOut_shipping_address__16O56 {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 1.5rem;
}
.CheckOut_shipping_address__16O56 h1 {
  font-size: 1.5rem;
  font-weight: 500;
}
.CheckOut_shipping_head__1nS_k {
  display: flex;
  flex-direction: column;
}
.CheckOut_shipping_head__1nS_k label {
  padding: 1.25rem 0;
}
.CheckOut_shipping_head__1nS_k input {
  /* width: 50%; */
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.CheckOut_email__3mLoh {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
}
.CheckOut_email_address__cXqm5 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.CheckOut_email_address__cXqm5 label {
  padding: 1.25rem 0;
}
.CheckOut_email_address__cXqm5 input {
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.CheckOut_conf_email__11NRd {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.CheckOut_conf_email__11NRd label {
  padding: 1.25rem 0;
}
.CheckOut_conf_email__11NRd input {
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.CheckOut_number__1dJhe {
  display: flex;
  flex-direction: column;
}
.CheckOut_number__1dJhe label {
  padding: 1.25rem 0;
}
.CheckOut_number__1dJhe input {
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.CheckOut_places__3UvQe {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.CheckOut_city__1lkKy {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.CheckOut_city__1lkKy label {
  padding: 1.25rem 0;
}
.CheckOut_city__1lkKy input {
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.CheckOut_region__23kcR {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.CheckOut_region__23kcR label {
  padding: 1.25rem 0;
}
.CheckOut_region__23kcR input {
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.CheckOut_post_code__T5gKE {
  display: flex;
  flex-direction: column;
}
.CheckOut_post_code__T5gKE label {
  padding: 1.25rem 0;
}
.CheckOut_post_code__T5gKE input {
  height: 35px;
  outline: none;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  padding: 0.25rem;
  background-color: #f6f6f6;
}
.CheckOut_shipping_method__2VT-0 {
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  margin: 2rem 0;
  padding: 1.5rem;
}
.CheckOut_method_head__LepfI h1 {
  font-size: 1.5rem;
  font-weight: 500;
}
.CheckOut_method_one__1onht {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  border: 1px solid #dbdbdb;
  padding: 1rem;
  border-radius: 5px;
}

.CheckOut_one_left__1DmZk {
  display: flex;
  flex-direction: row;
  width: 25%;
  font-size: 1.25rem;
}
.CheckOut_one_left__1DmZk p {
  padding: 0 0.25rem;
}
.CheckOut_one_right__1w6Ij {
  font-size: 1.25rem;
}
.CheckOut_order__2ew4P {
  width: 45%;
  height: 40%;
  margin: 0 1rem;
}
.CheckOut_ordera_summory__2Ke0_ {
  display: flex;
  flex-direction: column;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  padding: 1rem;
}
.CheckOut_order_head__2XOH8 h1 {
  font-size: 1.5rem;
  font-weight: 500;
}
.CheckOut_subtotal__2I-Yw {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}
.CheckOut_subtotal_left__337Oq {
  color: #bab5b5;
}
.CheckOut_subtotal_right__2rPAO p {
  font-size: 1.15rem;
}

.CheckOut_Discount__2OAsm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  color: #bab5b5;
}

.CheckOut_shippment__JHWQn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  color: #bab5b5;
}
.CheckOut_total__2GUQs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
}
.CheckOut_total_left__2TPmj p {
  font-size: 1.15rem;
}
.CheckOut_total_right__21V14 p {
  font-size: 1.5rem;
}
.CheckOut_payment_button__3VdC8 {
  margin-left: auto;
  margin-right: auto;
}
.CheckOut_payment_button__3VdC8 button {
  border: none;
  border-radius: 5px;
  padding: 0.25rem;
  font-size: 1rem;
  width: 200px;
  height: 40px;
  color: black;
  background-color: #cdcdcd;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .CheckOut_checkout_page__2dftX {
    display: flex;
    flex-direction: column;
    margin: 1rem;
  }
  .CheckOut_checkout__3zVO3 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .CheckOut_shipping_address__16O56 {
    padding: 1rem;
  }
  .CheckOut_shipping_address__16O56 h1 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .CheckOut_shipping_head__1nS_k label {
    padding: 1rem 0;
  }

  .CheckOut_email__3mLoh {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0;
  }
  .CheckOut_email_address__cXqm5 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .CheckOut_email_address__cXqm5 label {
    padding: 1rem 0;
  }
  .CheckOut_conf_email__11NRd {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .CheckOut_conf_email__11NRd label {
    padding: 1rem 0;
  }
  .CheckOut_number__1dJhe label {
    padding: 1rem 0;
  }
  .CheckOut_city__1lkKy {
    display: flex;
    flex-direction: column;
    width: 49%;
  }
  .CheckOut_city__1lkKy label {
    padding: 1rem 0;
  }
  .CheckOut_region__23kcR {
    display: flex;
    flex-direction: column;
    width: 49%;
  }
  .CheckOut_region__23kcR label {
    padding: 1rem 0;
  }
  .CheckOut_post_code__T5gKE label {
    padding: 1rem 0;
  }
  .CheckOut_shipping_method__2VT-0 {
    border-radius: 5px;
    border: 1px solid #c3c3c3;
    margin: 1rem 0;
    padding: 1.25rem;
  }
  .CheckOut_method_head__LepfI h1 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .CheckOut_method_one__1onht {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1rem 0;
    border: 1px solid #dbdbdb;
    padding: 1rem;
    border-radius: 5px;
  }

  .CheckOut_one_left__1DmZk {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 1rem;
  }
  .CheckOut_one_left__1DmZk p {
    padding: 0 0.25rem;
  }
  .CheckOut_one_right__1w6Ij {
    font-size: 1rem;
  }
  .CheckOut_order__2ew4P {
    width: 100%;
    height: 40%;
    margin: 0;
  }
  .CheckOut_order_head__2XOH8 h1 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .CheckOut_subtotal_right__2rPAO p {
    font-size: 1rem;
  }
  .CheckOut_total_left__2TPmj p {
    font-size: 1.25rem;
  }
  .CheckOut_total_right__21V14 p {
    font-size: 1.25rem;
  }
  .CheckOut_payment_button__3VdC8 button {
    border: none;
    border-radius: 5px;
    padding: 0.25rem;
    font-size: 0.95rem;
    width: 200px;
    height: 35px;
    color: black;
    background-color: #cdcdcd;
  }
}

.OrderConfirm_container__2kB1Y {
  height: 100%;
  width: 100%;
  font-family: "poppins", sans-serif;
}
.OrderConfirm_confirmation_page__d7-zL {
  display: flex;
  flex-direction: column;
  border: 1px solid #b5b3b3;
  margin: 2rem;
  width: 35%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}
.OrderConfirm_confirmation_head__13laD {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
}
.OrderConfirm_confirmation_head__13laD p {
  font-size: 0.75rem;
  color: #959595;
}
.OrderConfirm_head_up__1yorl {
  font-size: 4rem;
  color: rgb(18, 110, 18);
}
.OrderConfirm_head_bottom__1SC0O p {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(32, 30, 30);
}
.OrderConfirm_confirmation_body__hOP_f {
  margin: 1rem;
}
.OrderConfirm_body_up__xhgjM h2 {
  font-size: 1.25rem;
  font-weight: 600;
}
.OrderConfirm_body_up__xhgjM p {
  font-size: 0.95rem;
  color: #959595;
  padding: 0.5rem 0;
}
.OrderConfirm_body_bottom__3HvMl {
  padding: 0.75rem 0;
}
.OrderConfirm_body_bottom__3HvMl h2 {
  font-size: 1.25rem;
  font-weight: 600;
}
.OrderConfirm_body_bottom__3HvMl p {
  font-size: 0.95rem;
  color: #959595;
  padding: 0.5rem 0;
}
.OrderConfirm_order_detail__1pq8p {
  margin: 1rem;
}
.OrderConfirm_order_detail__1pq8p h2 {
  font-size: 1.5rem;
  font-weight: 600;
}
.OrderConfirm_order_rate__rLXy8 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 1rem 0;
}
.OrderConfirm_order_price__xX9aG {
  font-size: 1.25rem;
  font-weight: 600;
}
.OrderConfirm_order_left__3RRbH p {
  color: #959595;
}
.OrderConfirm_order_right__2VoGQ {
  color: #959595;
}
.OrderConfirm_total__1hEOm {
  font-size: 1.25rem;
}
.OrderConfirm_total_price__2KUPb {
  font-size: 1.25rem;
  font-weight: 600;
}
.OrderConfirm_order_button__3REOW {
  margin-left: auto;
  margin-right: auto;
}
.OrderConfirm_order_button__3REOW button {
  background: black;
  border: none;
  color: #fff;
  width: 350px;
  height: 40px;
  font-size: 1rem;
  margin: 1rem;
  padding: 0.25rem;
  border-radius: 5px;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .OrderConfirm_confirmation_page__d7-zL {
    display: flex;
    flex-direction: column;
    border: 1px solid #b5b3b3;
    margin: 1rem;
    width: 95%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
  }
  .OrderConfirm_head_up__1yorl {
    font-size: 3.5rem;
    color: rgb(18, 110, 18);
  }
  .OrderConfirm_head_bottom__1SC0O p {
    font-size: 1.25rem;
    font-weight: 600;
    color: rgb(32, 30, 30);
  }
  .OrderConfirm_confirmation_body__hOP_f {
    margin: 0.5rem;
  }
  .OrderConfirm_body_up__xhgjM h2 {
    font-size: 1.15rem;
    font-weight: 600;
  }
  .OrderConfirm_body_up__xhgjM p {
    font-size: 0.95rem;
    color: #959595;
    padding: 0.25rem 0;
  }
  .OrderConfirm_body_bottom__3HvMl {
    padding: 0.25rem 0;
  }
  .OrderConfirm_body_bottom__3HvMl h2 {
    font-size: 1.15rem;
    font-weight: 600;
  }
  .OrderConfirm_body_bottom__3HvMl p {
    font-size: 0.95rem;
    color: #959595;
    padding: 0.25rem 0;
  }
  .OrderConfirm_order_detail__1pq8p {
    margin: 0.5rem;
  }
  .OrderConfirm_order_detail__1pq8p h2 {
    font-size: 1.35rem;
    font-weight: 700;
  }
  .OrderConfirm_order_price__xX9aG {
    font-size: 1.15rem;
    font-weight: 600;
  }
  .OrderConfirm_total__1hEOm {
    font-size: 1.15rem;
  }
  .OrderConfirm_order_button__3REOW button {
    background: black;
    border: none;
    color: #fff;
    width: 325px;
    height: 40px;
    font-size: 1rem;
    margin: 0.75rem;
    padding: 0.25rem;
    border-radius: 5px;
  }
}

.Item_container__2bfsk {
  width: 100%;
  height: 100%;
  font-family: "poppins", sans-serif;
}
.Item_cart_page__3Es0q {
  display: flex;
  flex-direction: column;
}
.Item_page_top__iD0Hp {
  display: flex;
  flex-direction: row;
  margin: 2rem;
}
.Item_cart_image__2x78h {
  width: 50%;
  height: 50%;
}
.Item_cart_page__3Es0q img {
  width: 50%;
  height: 100%;
}
.Item_cart_product__1Z-bp {
  width: 50%;
  margin: 0.75rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Item_product_name__MWENA {
  text-align: start;
  font-family: "poppins";
}
.Item_product_name__MWENA h1 {
  font-weight: 300;
  font-size: 1.75rem;
}
.Item_product_name__MWENA h2 {
  font-size: 1.25rem;
}
.Item_detail__dYjPw {
  display: flex;
  flex-direction: row;
  margin: 0.75rem;
  align-items: center;
  width: 50%;
  justify-content: space-between;
}
.Item_rating__3LjSj {
  font-size: 1.75rem;
  color: gold;
  width: 40%;
}
.Item_review__1DufH {
  width: 50%;
  text-decoration: underline;
  font-size: 1.25rem;
  font-family: "poppins";
}
.Item_description__1lGrY {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Item_quantity__5MQ_X {
  display: flex;
  align-items: center;
  border: 1px solid black;
  width: 80px;
  padding: 0.5rem 0.75rem;
  border-radius: 15px;
  justify-content: space-between;
}
.Item_quantity__5MQ_X button {
  border: none;
  background: transparent;
  font-size: 1.5rem;
}
.Item_quantity__5MQ_X input {
  border: none;
  background: transparent;
  width: 30px;
  text-align: center;
  outline: none;
}
.Item_price__jA_nW {
  font-size: 1.5rem;
  padding: 1.25rem 0;
}
.Item_size_chart__2Tli4 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.Item_chart_head__2shg8 h1 {
  font-size: 1.5rem;
  font-weight: 500;
}
.Item_chart_button__dtEZX {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0.75rem 0;
}
.Item_chart_button__dtEZX button {
  background: transparent;
  border: 1px solid rgb(123, 123, 123);
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
  border-radius: 20px;
  padding: 0.25rem;
}
.Item_order__v_jkq {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
.Item_buy__1Dnzn button {
  background: transparent;
  border: 1px solid black;
  width: 170px;
  height: 50px;
  font-size: 1.25rem;
  padding: 0.75rem;
  border-radius: 5px;
}
.Item_cart__2ZSBZ {
  width: 170px;
  height: 50px;
  font-size: 1.25rem;
  padding: 0.75rem;
  background-color: black;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}
.Item_cart_text__1auCc button {
  border: none;
  background: transparent;
  font-size: 1.25rem;
  color: #fff;
}
.Item_page_bottom__1Fldc {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}
.Item_page_bottom__1Fldc h1 {
  font-size: 1.5rem;
  color: rgb(46, 28, 63);
}
.Item_related_product__35fkW {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.Item_card__6Uw6m {
  width: 300px;
  height: 250px;
  background-color: rgb(235, 243, 244);
  margin: 1rem;
  border-radius: 15px;
  /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
}
.Item_card_head__rhZzT {
  width: 100%;
  height: 50%;
}
.Item_card_head__rhZzT img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.Item_card_body__3dexh {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 1rem;
}
.Item_card_left__3Savy {
  display: flex;
  flex-direction: column;
}
.Item_card_right__htS6B {
  font-size: 1.25rem;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .Item_page_top__iD0Hp {
    display: flex;
    flex-direction: column;
    margin: 2rem;
  }
  .Item_cart_image__2x78h {
    width: 100%;
    height: auto;
  }
  .Item_cart_page__3Es0q img {
    width: 100%;
    height: 100%;
  }
  .Item_cart_product__1Z-bp {
    width: 100%;
    margin: 0.75rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .Item_product_name__MWENA h1 {
    font-weight: 300;
    font-size: 1.25rem;
  }
  .Item_product_name__MWENA h2 {
    font-weight: 500;
  }
  .Item_detail__dYjPw {
    display: flex;
    flex-direction: row;
    margin: 0.75rem 0;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .Item_rating__3LjSj {
    font-size: 1.5rem;
    color: gold;
    width: 50%;
  }
  .Item_review__1DufH {
    width: 50%;
    text-decoration: underline;
    font-size: 1rem;
    font-family: "poppins";
  }
  .Item_description__1lGrY {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .Item_quantity__5MQ_X {
    display: flex;
    align-items: center;
    border: 1px solid black;
    width: 80px;
    padding: 0.5rem 0.75rem;
    border-radius: 15px;
    justify-content: space-between;
  }
  .Item_quantity__5MQ_X button {
    border: none;
    background: transparent;
    font-size: 1.25rem;
  }
  .Item_quantity__5MQ_X input {
    border: none;
    background: transparent;
    width: 30px;
    text-align: center;
    outline: none;
  }
  .Item_price__jA_nW {
    font-size: 1.25rem;
    padding: 0;
    margin: 0 2.25rem;
  }
  .Item_size_chart__2Tli4 {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin: 1.25rem 0;
  }
  .Item_chart_head__2shg8 h1 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .Item_chart_button__dtEZX {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0.75rem 0;
  }
  .Item_chart_button__dtEZX button {
    background: transparent;
    border: 1px solid rgb(123, 123, 123);
    width: 40px;
    height: 40px;
    font-size: 1rem;
    border-radius: 20px;
    padding: 0.25rem;
  }

  .Item_order__v_jkq {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .Item_buy__1Dnzn button {
    background: transparent;
    border: 1px solid black;
    width: 135px;
    height: 45px;
    font-size: 1rem;
    padding: 0.75rem;
    border-radius: 5px;
  }
  .Item_cart__2ZSBZ {
    width: 135px;
    height: 47px;
    font-size: 1rem;
    padding: 0.75rem;
    background-color: black;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
  }
  .Item_cart_text__1auCc button {
    border: none;
    background: transparent;
    font-size: 1rem;
    color: #fff;
  }
  .Item_page_bottom__1Fldc {
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
  }
  .Item_page_bottom__1Fldc h1 {
    font-size: 1.25rem;
    color: rgb(46, 28, 63);
  }
  .Item_related_product__35fkW {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 0%;
  }

  .Item_card__6Uw6m {
    width: 295px;
    height: 215px;
    background-color: rgb(235, 243, 244);
    margin: 1rem;
    border-radius: 15px;
    /* display: flex;
      flex-direction: column;
      justify-content: space-between; */
  }
  .Item_card_head__rhZzT {
    width: 70%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .Item_card_head__rhZzT img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .Item_card_body__3dexh {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1rem;
  }
  .Item_card_left__3Savy {
    display: flex;
    flex-direction: column;
  }
  .Item_card_right__htS6B {
    font-size: 1.25rem;
  }
}

.OrderDetail_container__3bWDp {
  height: 100%;
  width: 100%;
  font-family: "poppins", sans-serif;
  /* background-color: #efefef; */
}
.OrderDetail_detail_page__3EkSW {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}
.OrderDetail_detail_head__3XYv8 {
  border-bottom: 1px solid #b0b0b0;
}
.OrderDetail_detail_head__3XYv8 h1 {
  font-weight: 500;
}
.OrderDetail_order_one__Pow_i {
  display: flex;
  flex-direction: column;
  margin: 3rem;
}
.OrderDetail_one_head__1yUk6 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b0b0b0;
}
.OrderDetail_head_left__2LOu3 h1 {
  font-size: 1.75rem;
  font-weight: 400;
}
.OrderDetail_head_right__sjJmH{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.OrderDetail_head_right__sjJmH p {
  font-size: 1.25rem;
}
.OrderDetail_orders__IjJMJ {
  display: flex;
  flex-direction: column;
}
.OrderDetail_item_one__2N4Vt {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}
.OrderDetail_item_number__Ywzst {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 15%;
}
.OrderDetail_number_up__1iKwI p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.OrderDetail_number_bottom__GXJwN p {
  font-size: 1.25rem;
  color: #000000;
}
.OrderDetail_item_name__2YpcQ {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.OrderDetail_name_up__26Ush p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.OrderDetail_name_bottom__3z82l p {
  font-size: 1.25rem;
  color: #000000;
}
.OrderDetail_item_quantity__2nLka {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.OrderDetail_quantity_up__245H7 p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.OrderDetail_quantity_bottom__3hIgs p {
  font-size: 1.25rem;
  color: #000000;
}
.OrderDetail_item_price__X-XF_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 15%;
}
.OrderDetail_price_up__1bnrm p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.OrderDetail_price_bottom__2eiGv p {
  font-size: 1.25rem;
  color: #000000;
  /* padding: 0.75rem 0; */
}
.OrderDetail_item_tottal__2lDgN {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 15%;
}
.OrderDetail_tottal_up__2hdUn p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.OrderDetail_tottal_bottom__1OIAK p {
  font-size: 1.25rem;
  color: #000000;
}
.OrderDetail_item_status__W7kb6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 20%;
}
.OrderDetail_status_up__2AJyg p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.OrderDetail_status_bottom__1p9mB p {
  font-size: 1.25rem;
  color: #000000;
}
.OrderDetail_price__1s4UG {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20%;
  padding: 3rem 0;
}
.OrderDetail_subtotal__2eAQI {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.OrderDetail_subtotal_left__1c8Hj p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.OrderDetail_subtotal_right__R8RaQ {
  font-size: 1.25rem;
  color: #000000;
}
.OrderDetail_shipping__33TxD {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.OrderDetail_shipping_left__OOJWC p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.OrderDetail_shipping_right__2lJb9 p {
  font-size: 1.25rem;
  color: #000000;
}
.OrderDetail_tax__3Yzkp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
}
.OrderDetail_tax_left__1tnIz p {
  font-size: 1.25rem;
  color: #7f7d7d;
}
.OrderDetail_tax_right__3TX2Z {
  font-size: 1.25rem;
  color: #000000;
}
.OrderDetail_grant_total__J-mYL {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}
.OrderDetail_total_left__3SUQ3 {
  font-size: 1.5rem;
  color: #7f7d7d;
}
.OrderDetail_total_right__2pnjG {
  font-size: 1.5rem;
  color: #000000;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .OrderDetail_detail_page__3EkSW {
    display: flex;
    flex-direction: column;
    margin: 0.75rem;
  }
  .OrderDetail_detail_head__3XYv8 h1 {
    font-weight: 500;
    font-size: 1.5rem;
  }
  .OrderDetail_order_one__Pow_i {
    display: flex;
    flex-direction: column;
    margin: 0.75rem 0.25rem;
  }

  .OrderDetail_head_left__2LOu3 h1 {
    font-size: 1rem;
    font-weight: 400;
  }
  .OrderDetail_head_right__sjJmH p {
    font-size: 1rem;
  }
  .OrderDetail_orders__IjJMJ {
    overflow-x: scroll;
  }
  .OrderDetail_item_one__2N4Vt {
    display: flex;
    flex-direction: row;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }
  .OrderDetail_item_number__Ywzst {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 75px;
  }
  .OrderDetail_number_up__1iKwI p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .OrderDetail_number_bottom__GXJwN p {
    font-size: 1rem;
    color: #000000;
  }

  .OrderDetail_item_name__2YpcQ {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .OrderDetail_name_up__26Ush p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .OrderDetail_name_bottom__3z82l p {
    font-size: 1rem;
    color: #000000;
  }
  .OrderDetail_item_quantity__2nLka {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .OrderDetail_quantity_up__245H7 p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .OrderDetail_quantity_bottom__3hIgs p {
    font-size: 1rem;
    color: #000000;
  }
  .OrderDetail_item_price__X-XF_ {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100px;
  }
  .OrderDetail_price_up__1bnrm p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .OrderDetail_price_bottom__2eiGv p {
    font-size: 1rem;
    color: #000000;
    /* padding: 0.75rem 0; */
  }
  .OrderDetail_item_tottal__2lDgN {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100px;
  }
  .OrderDetail_tottal_up__2hdUn p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .OrderDetail_tottal_bottom__1OIAK p {
    font-size: 1rem;
    color: #000000;
  }
  .OrderDetail_item_status__W7kb6 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100px;
  }
  .OrderDetail_status_up__2AJyg p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .OrderDetail_status_bottom__1p9mB p {
    font-size: 1rem;
    color: #000000;
  }
  .OrderDetail_price__1s4UG {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 75%;
    padding: 1rem 0;
  }
  .OrderDetail_subtotal__2eAQI {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .OrderDetail_subtotal_left__1c8Hj p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .OrderDetail_subtotal_right__R8RaQ {
    font-size: 1rem;
    color: #000000;
  }
  .OrderDetail_shipping__33TxD {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .OrderDetail_shipping_left__OOJWC p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .OrderDetail_shipping_right__2lJb9 p {
    font-size: 1rem;
    color: #000000;
  }
  .OrderDetail_tax__3Yzkp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
  }
  .OrderDetail_tax_left__1tnIz p {
    font-size: 1rem;
    color: #7f7d7d;
  }
  .OrderDetail_tax_right__3TX2Z {
    font-size: 1rem;
    color: #000000;
  }
  .OrderDetail_total_left__3SUQ3 {
    font-size: 1.15rem;
    color: #7f7d7d;
  }
  .OrderDetail_total_right__2pnjG {
    font-size: 1.15rem;
    color: #000000;
  }
}

.SearchList_container__3Y3CX{
    width: 100%;
    height: 90vh;
    background-color: #f5f2ff;
    font-family: "poppins", sans-serif;
}
.SearchList_search_page__2SE6H{
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
}
.SearchList_product_card__ZH4nc{
    width: 350px;
    height: 350px;
    /* border: 1px solid #f1edf4; */
    margin: 1.5rem 0;
    padding: 1.25rem;
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: all 0.2s ease-in-out;
}
.SearchList_product_card__ZH4nc:hover {
    margin-top: 3px;
    box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
  }
.SearchList_product_image__3GhOj{
    width: 60%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}
.SearchList_product_image__3GhOj img{
    width: 100%;
    height: 100%;
}
.SearchList_card_body__RNj16{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    align-items: flex-start;
}
.SearchList_product_description__g1qZ7{
    display: flex;
    flex-direction: column;
  
    justify-content: center;
}
.SearchList_product_name__IhlDM p{
    font-family: "poppins";
    font-size: 1.5rem;
    color: #24172d;
    font-weight: 600;
}
.SearchList_product_rate__xT5Db p{
    font-family: "poppins";
    font-size: 1.25rem;
    color: #24172d;
}
.SearchList_cart__3luNE{
    font-size: 1.75rem;
    cursor: pointer;
}
.SearchList_product_firstrow__2ixj0{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.SearchList_quantity_popup__ogApg{
    width: 350px;
    height: 325px;
    padding: 1.25rem;
    background-color: #322d2d;
    border-radius: 2rem;
    color: #fff;
    position: absolute;
    top: 40%;
    right: 42%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.SearchList_popup_page__3VPtY{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 100%;
}
.SearchList_popup_close__1K0sW{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    font-size: 1.5rem;
}
.SearchList_popup_page__3VPtY h4{
    font-size: 1.25rem;
}
.SearchList_popup_top__3-oJW{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.SearchList_quantity__37L7y{
    font-size: 1.25rem;
}
.SearchList_quantity_value__2EyRe{
    display: flex;
    align-items: center;
    border:3px solid #d4c7ca;
    padding: 0.5rem 1.25rem;
    border-radius: 15px;
    justify-content: space-between;
    width: 60%;
    background-color: #fff;
}
.SearchList_quantity_value__2EyRe button{
    border: none;
    background: transparent;
  
    font-size: 1.75rem;
}
.SearchList_quantity_value__2EyRe input{
    width: 30%;
    border: none;
    background: none;
    outline: none;
    font-size: 1.5rem;

}
.SearchList_popup_bottom__FG9S8{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.SearchList_bulk_item__3Q1q4{
    font-size: 1.25rem;
}
.SearchList_item_value__1WH02 input{
    outline: none;
    border: none;
    font-size: 1.25rem;
    padding: 0.5rem;
    width: 185px;
    border-radius: 8px;
    border:3px solid #d4c7ca;
} 
.SearchList_cart_button__2ZTT-{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}
.SearchList_cart_button__2ZTT- button{
    border: none;
    width: 130px;
    height: 45px;
    border-radius: 0.5rem;
    background: #151623;
    color: #fff;
    font-size: 1rem;
    padding: 1rem;
    cursor: pointer;
}
.ProductSelection_container__2vIRI {
  width: 100%;
  height: 90vh;
  background-color: #f5f2ff;
  font-family: "poppins", sans-serif;
  display: flex;
  flex-direction: row;
}
.ProductSelection_selection_page__1vvcx {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}
.ProductSelection_product_name__2ljft {
  border-bottom: 2px solid #34343d;
  width: 50%;
}
.ProductSelection_product_name__2ljft h2 {
  font-size: 2rem;
  font-weight: 600;
}
.ProductSelection_section_selection__157ip {
  margin: 1rem;
  padding: 1rem;
}
.ProductSelection_section_selection__157ip h3 {
  font-size: 1.5rem;
  font-weight: 500;
}
.ProductSelection_selection_container__3X9BD {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.ProductSelection_catogry__j2I3V {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* flex-wrap: wrap; */
  margin: 1rem 0;
}
.ProductSelection_item_card__wLV-e {
  width: 350px;
  height: 350px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 1rem;
  margin: 0 1rem;
  transition: all 0.2s ease-in-out;
}
.ProductSelection_item_card__wLV-e:hover {
  margin-top: 3px;
  box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
}
.ProductSelection_item_top__3_tdb {
  font-size: 120px;
}
.ProductSelection_item_bottom__1v0bW p {
  font-size: 1.5rem;
}
.ProductSelection_bulkitem_card__2J8H5 {
  width: 350px;
  height: 350px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 1rem;
  margin: 0 1rem;
  transition: all 0.2s ease-in-out;
}
.ProductSelection_bulkitem_card__2J8H5:hover {
  margin-top: 3px;
  box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
}
.ProductSelection_bulkitem_top__1fMAW {
  font-size: 120px;
}
.ProductSelection_bulkitem_bottom__Sy2vU p {
  font-size: 1.5rem;
}
.ProductSelection_item_selection__3cA0Q {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 50%;
}
.ProductSelection_chart_head__1Vk6H {
  display: flex;
  flex-direction: column;
  height: 40%;
  background: #322d2d;
  width: 80%;
  padding: 1rem;
  color: #fff;
  justify-content: space-between;
  border-radius: 0.5rem;
}
.ProductSelection_size_name__3kqxr h2 {
  font-size: 1.25rem;
  font-weight: 400;
}
.ProductSelection_size_chart__3kvJG {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: space-around;
}
.ProductSelection_size_chart__3kvJG button {
  border: none;
  font-size: 1.25rem;
  padding: 0.75rem;
  border-radius: 30px;
  width: 50px;
  background-color: #fff;
}
.ProductSelection_quantity__3eplr {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}
.ProductSelection_quantity_name___FKL_ p {
  font-size: 1.25rem;
}
.ProductSelection_quantity_add__3zggt {
  display: flex;
  align-items: center;
  /* border:3px solid #d4c7ca; */
  padding: 0.5rem 1rem;
  border-radius: 15px;
  justify-content: space-between;
  width: 30%;
  background-color: #fff;
}
.ProductSelection_quantity_add__3zggt button {
  border: none;
  background: transparent;

  font-size: 1.75rem;
}
.ProductSelection_quantity_add__3zggt input {
  width: 30%;
  border: none;
  background: none;
  outline: none;
  font-size: 1.5rem;
}
.ProductSelection_section_bottom__jjf25 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.ProductSelection_cancel__3RVns button {
  border: none;
  width: 130px;
  height: 45px;
  border-radius: 0.5rem;
  background: #ffffff;
  color: #111225;
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
}
.ProductSelection_bulk_cancel__2YBtj button {
  border: none;
  width: 130px;
  height: 45px;
  border-radius: 0.5rem;
  background: #ffffff;
  color: #111225;
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
}
.ProductSelection_cart_button__2m0ej button {
  border: none;
  width: 130px;
  height: 45px;
  border-radius: 0.5rem;
  background: #111225;
  color: #fff;
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
}
.ProductSelection_bulkcart_button__2SoL4 button {
  border: none;
  width: 130px;
  height: 45px;
  border-radius: 0.5rem;
  background: #111225;
  color: #fff;
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
}
.ProductSelection_new_standard__2tOVX {
  display: flex;
  flex-direction: column;
  height: 220px;
  background: #322d2d;
  width: 100%;
  padding: 1rem;
  color: #fff;
  justify-content: space-between;
  border-radius: 0.5rem;
}
.ProductSelection_nw_cancel__3wh8_ button {
  border: none;
  width: 120px;
  height: 40px;
  border-radius: 0.5rem;
  background: #ffffff;
  color: #111225;
  font-size: 1rem;

  cursor: pointer;
}
.ProductSelection_bulkcart_new__36biE button {
  border: none;
  width: 120px;
  height: 40px;
  border-radius: 0.5rem;
  background: #0b0c2a;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}
.ProductSelection_bulk_selection_page___Glpa {
  display: flex;
  flex-direction: column;
  background: #322d2d;
  width: 50%;
  padding: 1rem;
  color: #fff;
  justify-content: space-between;
  border-radius: 0.5rem;
  height: 200px;
}
.ProductSelection_bulk_heed__2ncDA h2 {
  font-size: 1.25rem;
  font-weight: 400;
}
.ProductSelection_standard_case__gRRTr {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.ProductSelection_special_case__pBwyE {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.ProductSelection_size_value__334s0 {
  font-size: 1.25rem;
}
.ProductSelection_size_value__334s0 select {
  outline: none;
  border: none;
  font-size: 1rem;
  width: 55px;
  border-radius: 3px;
  padding: 0.5rem;
}
.ProductSelection_case_size__1dMWL {
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: space-between;
  align-items: center;
}
.ProductSelection_case_input__Vb0dG {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}
.ProductSelection_input_value__xCvv9 input {
  border-radius: 4px;
  border: none;
  outline: none;
  width: 80px;
  font-size: 1rem;
  padding: 0.35rem;
}
.ProductSelection_standardquantity_name__3_AhX p {
  font-size: 1rem;
}
.ProductSelection_standardquantity_add__2Fkxa {
  display: flex;
  align-items: center;
  /* border:3px solid #d4c7ca; */
  padding: 0.5rem 1rem;
  border-radius: 15px;
  justify-content: space-between;
  width: 35%;
  background-color: #fff;
}
.ProductSelection_standardquantity_add__2Fkxa button {
  border: none;
  background: transparent;

  font-size: 1.75rem;
}
.ProductSelection_standardquantity_add__2Fkxa input {
  width: 12%;
  border: none;
  background: none;
  outline: none;
  font-size: 1rem;
}
.ProductSelection_standard_quantity__1lNBd {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}
.ProductSelection_bulk_selection__3d-x2 {
  display: flex;
  flex-direction: column;
  background: #322d2d;
  width: 50%;
  padding: 1rem;
  color: #fff;
  justify-content: space-between;
  border-radius: 0.5rem;
  height: 300px;
}
.ProductSelection_specialcase_name__3KJHo {
  font-size: 1.25rem;
}
.ProductSelection_top_head__3rKqF {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.ProductSelection_special_headname__3O893 h3 {
  font-size: 1.5rem;
  font-weight: 700;
}
.ProductSelection_popup_page__1R-ke {
  position: absolute;
  right: 3rem;
  bottom: 2rem;
  width: 300px;
  background: #000000;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #fff;
  text-align: center;
  justify-content: center;
  color: #fdfffd;
  border-radius: 5px;
  line-height: 0;
}
.ProductSelection_popup_sucess__1TAig {
  font-size: 1.75rem;
  color: green;
}
.ProductSelection_popup_cart__1GTYA {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}
.ProductSelection_popup__1E-Pp p {
  font-size: 0.9rem;
}
.ProductSelection_cart_title__2Gzzr p {
  font-size: 0.9rem;
}
.ProductSelection_cart_icon__sK1yt {
  font-size: 0.9rem;
}
.ProductSelection_selectQuantity__fvD4B {
  display: none;
}
.ProductSelection_mobSize__3h30C {
  display: none;
}
.ProductSelection_selection_catogory__1D1yK {
}
.ProductSelection_catogory_drop__3ZdEj {
  display: flex;
  width: 120%;
  justify-content: space-between;
  align-items: center;
}
.ProductSelection_catogory_drop__3ZdEj h3 {
  font-size: 1.25rem;
  font-weight: 400;
}
.ProductSelection_catogory_drop__3ZdEj select {
  width: 185px;
  border: 1px solid #4d4dad;
  height: 30px;
  border-radius: 6px;
  font-size: 1rem;
  text-align: center;
  padding: 0.25rem;
  color: #86350c;
  outline: #64f142;
}
.ProductSelection_mob_catogory__2s0Ga {
  display: none;
}
.ProductSelection_special_page__unxY-{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 275px;
}

/* mobile */

@media only screen and (max-width: 767px) {
  .ProductSelection_container__2vIRI {
    width: 100%;
    height: 90vh;
    background-color: #ffffff;
    font-family: "poppins", sans-serif;
    display: flex;
    flex-direction: column;
  }
  .ProductSelection_selection_page__1vvcx {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  .ProductSelection_selection_container__3X9BD {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .ProductSelection_product_name__2ljft {
    border-bottom: 2px solid #34343d;
    width: 100%;
  }
  .ProductSelection_product_name__2ljft h2 {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .ProductSelection_section_selection__157ip {
    margin: 1rem;
    padding: 0.5rem;
  }
  .ProductSelection_section_selection__157ip h3 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .ProductSelection_catogry__j2I3V {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5rem 0;
    width: 100%;
    justify-content: space-between;
  }
  .ProductSelection_item_card__wLV-e {
    width: 48%;
    height: 150px;
    background: #e7e4e4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 1rem;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
  .ProductSelection_item_top__3_tdb {
    font-size: 50px;
  }
  .ProductSelection_item_bottom__1v0bW p {
    font-size: 0.85rem;
  }
  .ProductSelection_bulkitem_card__2J8H5 {
    width: 48%;
    height: 150px;
    background: #c3c0c0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 1rem;
    margin: 1rem;
    transition: all 0.2s ease-in-out;
  }
  .ProductSelection_bulkitem_top__1fMAW {
    font-size: 40px;
  }
  .ProductSelection_bulkitem_bottom__Sy2vU p {
    font-size: 0.85rem;
  }
  .ProductSelection_item_selection__3cA0Q {
    display: flex;
    flex-direction: column;
    padding: 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  .ProductSelection_chart_head__1Vk6H {
    display: flex;
    flex-direction: column;
    height: 200px;
    background: #322d2d;
    width: 100%;
    padding: 1rem;
    color: #fff;
    justify-content: space-between;
    border-radius: 0.5rem;
  }

  .ProductSelection_size_name__3kqxr h2 {
    font-size: 1rem;
    font-weight: 400;
  }
  .ProductSelection_size_chart__3kvJG {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
  }
  .ProductSelection_size_chart__3kvJG button {
    border: none;
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 30px;
    width: 35px;
    background-color: #fff;
  }
  .ProductSelection_quantity__3eplr {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .ProductSelection_quantity_name___FKL_ p {
    font-size: 1rem;
  }
  .ProductSelection_quantity_add__3zggt {
    display: flex;
    align-items: center;
    /* border: 3px solid #d4c7ca; */
    padding: 0.5rem 1rem;
    border-radius: 15px;
    justify-content: space-between;
    width: 30%;
    background-color: #fff;
  }
  .ProductSelection_quantity_add__3zggt button {
    border: none;
    background: transparent;

    font-size: 1.25rem;
  }
  .ProductSelection_quantity_add__3zggt input {
    width: 30%;
    border: none;
    background: none;
    outline: none;
    font-size: 1.25rem;
  }
  .ProductSelection_section_bottom__jjf25 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .ProductSelection_cancel__3RVns button {
    border: none;
    width: 120px;
    height: 35px;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #111225;
    font-size: 1rem;
    padding: 0rem;
    cursor: pointer;
  }
  .ProductSelection_cart_button__2m0ej button {
    border: none;
    width: 120px;
    height: 40px;
    border-radius: 0.5rem;
    background: #111225;
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem;
    cursor: pointer;
  }
  .ProductSelection_bulk_selection_page___Glpa {
    display: flex;
    flex-direction: column;
    height: 300px;
    background: #322d2d;
    width: 100%;
    padding: 0.75rem;
    color: #fff;
    justify-content: space-between;
    border-radius: 0.5rem;
  }

  .ProductSelection_bulk_heed__2ncDA h2 {
    font-size: 1rem;
    font-weight: 400;
  }
  .ProductSelection_bulk_selection_page___Glpa h3 {
    font-size: 1.05rem;
  }

  .ProductSelection_standard_case__gRRTr {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .ProductSelection_case_name__Nu6nu p {
    font-size: 0.75rem;
  }
  .ProductSelection_case_value__b0KY1 {
    font-size: 0.75rem;
  }

  .ProductSelection_special_case__pBwyE {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .ProductSelection_size_value__334s0 select {
    outline: none;
    border: none;
    font-size: 0.75rem;
    width: 50px;
    border-radius: 3px;
    padding: 0.15rem;
  }
  .ProductSelection_case_size__1dMWL {
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: space-between;
    align-items: center;
  }
  .ProductSelection_case_input__Vb0dG {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }
  .ProductSelection_input_value__xCvv9 input {
    border-radius: 4px;
    border: none;
    outline: none;
    width: 60px;
    font-size: 0.75rem;
    padding: 0.15rem;
  }
  .ProductSelection_standardquantity_name__3_AhX p {
    font-size: 0.75rem;
  }
  .ProductSelection_standardquantity_add__2Fkxa {
    display: flex;
    align-items: center;
    /* border: 3px solid #d4c7ca; */
    padding: 0.5rem 0.75rem;
    border-radius: 15px;
    justify-content: space-between;
    width: 50%;
    background-color: #fff;
  }
  .ProductSelection_standardquantity_add__2Fkxa button {
    border: none;
    background: transparent;

    font-size: 1.2rem;
  }
  .ProductSelection_standardquantity_add__2Fkxa input {
    width: 20%;
    border: none;
    background: none;
    outline: none;
    font-size: 15px;
  }
  .ProductSelection_standard_quantity__1lNBd {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }

  .ProductSelection_bulk_cancel__2YBtj button {
    border: none;
    width: 80px;
    height: 30px;
    border-radius: 0.25rem;
    background: #ffffff;
    color: #111225;
    font-size: 12px;
    padding: 0.5rem;
    cursor: pointer;
  }
  .ProductSelection_bulkcart_button__2SoL4 button {
    border: none;
    width: 95px;
    height: 30px;
    border-radius: 0.25rem;
    background: #111225;
    color: #fff;
    font-size: 0.75rem;
    padding: 0.5rem;
    cursor: pointer;
  }
  .ProductSelection_selectQuantity__fvD4B {
    display: block;
    font-size: 15px;
  }
  .ProductSelection_webQuantity__3g5wh {
    display: none;
  }
  .ProductSelection_webSize__3qKw2 {
    display: none;
  }
  .ProductSelection_mobSize__3h30C {
    display: block;
    font-size: 15px;
  }
  .ProductSelection_web_catogory__QDJHe {
    display: none;
  }
  .ProductSelection_mob_catogory__2s0Ga {
    display: block;
  }
  .ProductSelection_new_standard__2tOVX {
    display: flex;
    flex-direction: column;

    background: #322d2d;
    width: 100%;
    padding: 1rem;
    color: #fff;
    justify-content: space-between;
    border-radius: 0.5rem;
  }
  .ProductSelection_new_head__1KhLn h2 {
    font-size: 1rem;
  }
  .ProductSelection_nw_cancel__3wh8_ button {
    border: none;
    width: 90px;
    height: 30px;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #111225;
    font-size: 0.75rem;
    cursor: pointer;
  }
  .ProductSelection_bulkcart_new__36biE button {
    border: none;
    width: 90px;
    height: 30px;
    border-radius: 0.5rem;
    background: #111225;
    color: #fff;
    font-size: 0.75rem;
    cursor: pointer;
  }
  .ProductSelection_catogory_drop__3ZdEj {
    display: flex;
    width: 120%;
    justify-content: space-between;
    align-items: center;
  }
  .ProductSelection_catogory_drop__3ZdEj h3 {
    font-size: 1rem;
    font-weight: 400;
  }
  .ProductSelection_catogory_drop__3ZdEj select {
    width: 150px;
    border: 1px solid #e7123b;
    border-radius: 6px;
    font-size: 15px;
    color: #193ec3;
  }
  .ProductSelection_bulk_selection__3d-x2 {
    display: flex;
    flex-direction: column;
    background: #322d2d;
    width: 100%;
    padding: 0.75rem;
    color: #fff;
    justify-content: space-between;
    border-radius: 0.5rem;
    height: 300px;
  }
  .ProductSelection_special_headname__3O893 h3 {
    font-size: 1rem;
    font-weight: 700;
  }
  .ProductSelection_specialcase_name__3KJHo p{
    font-size: 1rem;
  }
  .ProductSelection_size_value__334s0 {
    font-size: 15px;
  }
  .ProductSelection_special_page__unxY-{
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: space-between;
  }
}

.SubLevel_container__2iNGo{
    width: 100%;
    height: 90vh;
    background-color: #f5f2ff;
    font-family: "poppins", sans-serif;
}
.SubLevel_search_page__2z5wR{
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
}
.SubLevel_product_card__1PQoQ{
    width: 350px;
    height: 350px;
    /* border: 1px solid #f1edf4; */
    margin: 1.5rem 1rem;
    padding: 1.25rem;
    background-color: #fff;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: all 0.2s ease-in-out;
}
.SubLevel_product_card__1PQoQ:hover {
    margin-top: 3px;
    box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
  }
.SubLevel_product_image__1hlaf{
    width: 60%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}
.SubLevel_card_body__3w2h3{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    align-items: flex-start;
}
.SubLevel_product_description__1Cllq{
    display: flex;
    flex-direction: column;
  
    justify-content: center;
}
.SubLevel_product_name__2TYQg p{
    font-family: "poppins";
    font-size: 1.5rem;
    color: #24172d;
    font-weight: 600;
}
.SubLevel_product_rate__3bc1m p{
    font-family: "poppins";
    font-size: 1.25rem;
    color: #24172d;
}
.SubLevel_cart__3IGgd{
    font-size: 1.75rem;
    cursor: pointer;
}
.SubLevel_product_firstrow__btCKz{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.OrderList_container__2bZSx {
  width: 100%;
  height: 90vh;
  font-family: "poppins", sans-serif;
  background-color: #fff;
}
.OrderList_list_page__1khBs {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
}
.OrderList_order__3W1Sz {
  margin: 1rem;
  border-bottom: 1px solid rgb(0, 0, 51);
}
.OrderList_order__3W1Sz h2 {
  font-size: 2.25rem;
  /* font-weight: 600; */
}
.OrderList_myorders__1NpLu {
  display: flex;
  flex-direction: column;
}
.OrderList_order_details__2GRya {
  background: #f2f2f2;
  width: 100%;
  height: 170px;
  margin: 0.75rem 0;
}
.OrderList_myorder_head__3e9ZL {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem;
}
.OrderList_list_number__rnjf- {
  font-size: 1.5rem;
  font-weight: 600;
  width: 16%;
}
.OrderList_order_id__2AU8B {
  font-size: 1.5rem;
  font-weight: 600;
  width: 16%;
}
.OrderList_order_name__hq0vP {
  font-size: 1.5rem;
  font-weight: 600;
  width: 20%;
}
.OrderList_order_date__1ZyWO {
  font-size: 1.5rem;
  font-weight: 600;
  width: 16%;
}
.OrderList_order_status__Q87J1 {
  font-size: 1.5rem;
  font-weight: 600;
  width: 16%;
}
.OrderList_order_total__3qLYn {
  font-size: 1.5rem;
  font-weight: 600;
  width: 16%;
}
.OrderList_myorder_bottom__32ic1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.5rem;
}
.OrderList_number__lXE4i {
  font-size: 1rem;
  color: #740000;
  width: 16%;
}
.OrderList_id__1UDDB {
  font-size: 1rem;
  color: #740000;
  width: 15%;
}
.OrderList_name__20MSu {
  font-size: 1rem;
  color: #740000;
  width: 15%;
}
.OrderList_date__3zFoO {
  font-size: 1rem;
  color: #740000;
  width: 15%;
}
.OrderList_status__3gArW {
  font-size: 1rem;
  color: #740000;
  width: 15%;
}
.OrderList_total__1fLRa {
  font-size: 1rem;
  color: #740000;
  width: 11%;
}
.OrderList_order_icon__20rfH {
  font-size: 1.5rem;
  color: #3c2f7d;
  cursor: pointer;
  width: 5%;
}
.OrderList_icon_suggestion__2zMjk {
  right: 30px;
  font-size: 1.15rem;
  position: absolute;
  color: #d3243f;
}
@media only screen and (max-width: 767px) {
  .OrderList_list_page__1khBs {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }
  .OrderList_order__3W1Sz h2 {
    font-size: 2rem;
    font-weight: 600;
  }

  .OrderList_order_details__2GRya {
    background: #f2f2f2;
    width: 100%;
    height: 100px;
    margin: 0.5rem 0;
    overflow-x: scroll;
  }
  .OrderList_myorder_head__3e9ZL {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: fit-content; */
    justify-content: space-between;
    padding: 0.5rem;
  }
  .OrderList_list_number__rnjf- {
    font-size: 0.85rem;
    font-weight: 500;
    width: 12%;
  }
  .OrderList_order_id__2AU8B {
    font-size: 0.85rem;
    font-weight: 500;
    width: 22%;
  }
  .OrderList_order_name__hq0vP {
    font-size: 0.85rem;
    font-weight: 500;
    width: 25%;
  }
  .OrderList_order_date__1ZyWO {
    font-size: 0.85rem;
    font-weight: 500;
    width: 25%;
  }
  .OrderList_order_status__Q87J1 {
    font-size: 0.85rem;
    font-weight: 500;
    width: 16%;
  }
  .OrderList_order_total__3qLYn {
    font-size: 0.85rem;
    font-weight: 500;
    width: 15%;
  }
  .OrderList_myorder_bottom__32ic1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* width: fit-content; */
    padding: 0 0.5rem;
  }
  .OrderList_number__lXE4i {
    font-size: 0.70rem;
    color: #740000;
    width: 17%;
  }
  .OrderList_id__1UDDB {
    font-size: 0.70rem;
    color: #740000;
    width: 27%;
  }
  .OrderList_name__20MSu {
    font-size: 0.70rem;
    color: #740000;
    width: 23%;
  }
  .OrderList_date__3zFoO {
    font-size: 0.70rem;
    color: #740000;
    width: 27%;
  }
  .OrderList_status__3gArW {
    font-size: 0.70rem;
    color: #740000;
    width: 20%;
  }
  .OrderList_total__1fLRa {
    font-size: 0.70rem;
    color: #740000;
    width: 22%;
  }
  .OrderList_order_icon__20rfH {
    font-size: 1rem;
    color: #3c2f7d;
    cursor: pointer;
    width: 0%;
  }
  .OrderList_icon_suggestion__2zMjk {
    right: 30px;
    font-size: 1.15rem;
    position: absolute;
    color: #d3243f;
  }
}

